import React, { useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Divider,
  Tabs,
  Tab,
  Dialog,
  DialogContent,
  Chip,
  Tooltip,
  TextField
} from "@mui/material";
import { ChevronRight, Info, SupportAgent } from "@mui/icons-material";
import "./pricing.scss";
import Sidebar from "../sidebar/sidebar";
import { companylogo } from "../../assets/images";
import PaymentSubscription from "../Payment";
import { Call, Email, WhatsApp } from "@material-ui/icons";
import { getCurrencySymbol, getSecureItem, setSecureItem, trialDaysRemaining } from "../../utilsFunctions";
import axios from "axios";
import { BaseUrl, ImageBaseUrl } from "../../Environment";
import { toast } from "react-toastify";

const Pricing = () => {
  toast.configure({});
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  let loggedinUser = getSecureItem("loggedinUser");
  let countryCurrency = getSecureItem("companyCountry");
  const trialActive = loggedinUser?.company?.trialActive;
  const tenure = loggedinUser?.company?.tenure;
  const trialDays = trialDaysRemaining(loggedinUser?.company?.createdAt);

  const [modalValue, setModalValue] = React.useState();
  const [value, setValue] = React.useState("1");
  const [tabValue, setTabValue] = React.useState(tenure == "monthly" ? "monthly" : "yearly");
  const [openReferralModal, setOpenReferralModal] = React.useState(false);
  const [referralCode, setreferralCode] = React.useState("");
  const [planData, setPlanData] = React.useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabsChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [openSupport, setOpenSupport] = React.useState(false);

  const handleClickOpenSupport = () => {
    setOpenSupport(true);
  };

  const handleCloseSupport = () => {
    setOpenSupport(false);
  };

  const plansData = [
    {
      id: "0",
      name: trialActive ? "Trial" : "Free",
      image: "https://mui.com/static/branding/pricing/x-plan-pro.svg",
      // originalPrice: rates[countryCurrency?.symbol] && convert(500),
      originalPrice: 500,
      discountedPrice: 0,
      centerBoxText: "Free Forever",
      features: [
        "10 Active Loans",
        "20 Borrowers",
        trialActive ? "3 User" : "1 User",
        trialActive ? "Unlimited Plan's Feature" : "No Support",
        trialActive ? "" : "No Backup",
      ],
    },
    {
      id: "1",
      name: "Starter",
      image: "https://mui.com/static/branding/pricing/x-plan-pro.svg",
      // originalPrice: rates[countryCurrency?.symbol] && convert(7000),
      // discountedPrice: rates[countryCurrency?.symbol] && convert(4000),
      originalPrice: 7000,
      discountedPrice: tabValue == "monthly" ? 399 : 3999,
      centerBoxText: "40% Discount",
      features: [
        "500 Active Loans",
        "1000 Borrowers",
        "3 Users",
        "Limited Customer Support",
        "Manual Backups",
      ],
    },
    {
      id: "2",
      name: "Business Plus",
      image: "https://mui.com/static/branding/pricing/x-plan-community.svg",
      // originalPrice: rates[countryCurrency?.symbol] && convert(11000),
      // discountedPrice: rates[countryCurrency?.symbol] && convert(7000),
      originalPrice: 11000,
      discountedPrice: tabValue == "monthly" ? 699 : 6999,
      centerBoxText: "40% Discount",
      features: [
        "1000 Active Loans",
        "2000 Borrowers",
        "5 Users",
        "Priority Customer Support",
        "Manual Backups",
      ],
    },
    {
      id: "3",
      name: "Unlimited",
      image: "https://mui.com/static/branding/pricing/x-plan-premium.svg",
      // originalPrice: rates[countryCurrency?.symbol] && convert(18000),
      // discountedPrice: rates[countryCurrency?.symbol] && convert(11000),
      originalPrice: 18000,
      discountedPrice: tabValue == "monthly" ? 999 : 9999,
      centerBoxText: "40% Discount",
      features: [
        "Unlimited Active Loans",
        "Unlimited Borrowers",
        "Unlimited Users",
        "24/7 Priority Customer Support",
        "Automatic Backups",
      ],
    },
  ];

  const salientFeaturesData = [
    {
      name: "SALIENT FEATURES",
      img: "https://mui.com/static/branding/product-toolpad-light.svg",
      subData: [
        {
          name: "Accounting",
          data: ["No", "No", "Yes"],
        },
        {
          name: "Generate Loan Agreements",
          data: ["No", "Yes", "Yes"],
        },
        { name: "Team Roles and Permissions", data: ["No", "Yes", "Yes"] }, 
        { name: "Detailed Analysis Reports", data: ["Yes", "Yes", "Yes"] },
        { name: "Expense Tracker", data: ["Yes", "Yes", "Yes"] }, 
      ],
    },
  ];

  const commonPlanData = [
    {
      name: "LOAN MANAGEMENT",
      img: "https://mui.com/static/branding/product-core-light.svg",
      subData: [
        { name: "Loan Product Customization" },
        { name: "Penalty configuration" },
        { name: "Double Entry Accounting System (Cash Ledger)" },
        { name: "Multiple Areas & Lines" },
        { name: "Payment Reversal" },
        { name: "Direct/EMI Method loans (Daily/Weekly/Monthly)" },
      ],
    },
    {
      name: "BORROWER MANAGEMENT",
      img: "https://mui.com/static/branding/product-advanced-light.svg",
      subData: [
        { name: "Know your customers (KYC)" },
        { name: "Digilocker - KYC review and approval" },
        { name: "Free SMS Reminders" },
        { name: "Free SMS/WhatsApp Receipt Generations" },
        { name: "Geo-Mapping of your Borrowers" },
      ],
    },
    {
      name: "REPORTING",
      img: "https://mui.com/static/branding/product-core-light.svg",
      subData: [
        { name: "Loan Wise Reports" },
        { name: "Pending Dues Reports" },
        { name: "Customer Wise Reports" },
        { name: "Day-Wise Collection Reports" },
      ],
    },
/*     {
      name: "PAYMENTS",
      img: "https://mui.com/static/branding/product-templates-light.svg",
      subData: [
        { name: "Cards" },
        { name: "Wallet" },
        { name: "Direct debits" },
        { name: "UPI" },
      ],
    }, */


/*     {
      name: "INTEGRATIONS",
      img: "https://mui.com/static/branding/product-advanced-light.svg",
      subData: [
        { name: "Net Banking" },
        { name: "eNach" },
        { name: "UPI" },
        { name: "International Cards" },
        { name: "RuPay" },
        { name: "Wallet (Gpay)" },
      ],
    }, */
    {
      name: "CHANNELS",
      img: "https://mui.com/static/branding/product-templates-light.svg",
      subData: [
        { name: "Web" },
        { name: "Android" },
        { name: "iOS (coming soon)" },
        { name: "123UPIPAY" },
      ],
    },
    {
      name: "SECURITY",
      img: "https://mui.com/static/branding/product-toolpad-light.svg",
      subData: [
        { name: "2 Factor Authentication" },
        { name: "Biometric Lock" },
        { name: "Geo Mapping" },
      ],
    },
    {
      name: "SUPPORT",
      img: "https://mui.com/static/branding/product-designkits-light.svg",
      subData: [{ name: "Freshworks" }, { name: "In-app support" }],
    },
    {
      name: "ROSCA SAVINGS (coming soon)",
      img: "https://mui.com/static/branding/product-designkits-light.svg",
      subData: [
        { name: "Chit Fund products" },
        { name: "Investment Products" },
      ],
    },
/*     {
      name: "TEAM MANAGEMENT",
      img: "https://mui.com/static/branding/product-toolpad-light.svg",
      subData: [
       // { name: "Roles and Permissions" },
        { name: "Notes and other tools" },
        { name: "Staff Management" },
        { name: "Mobile and Web access" },
      ],
    }, */
  ];

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openInNewTab = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  const GeneratePayLinkForSub = async (data) => {
    const loggedinUser = getSecureItem("loggedinUser");
    const PaymentLinkBaseURL = `${ImageBaseUrl}api/payment-links`;

    try {
      const response = await axios.post(
        `${PaymentLinkBaseURL}/create-payment-link`,
        {
          amount: data?.amount,
          borrowerEmail: loggedinUser?.userEmail,
          borrowerPhone: loggedinUser?.userPhone,
          linkNotes: {
            companyId: loggedinUser?.companyId,
            planId: data?.planId,
            tenure: tabValue,
            companyIdReferedBy: data?.companyId
          },
          showReturnUrl: true
        },
      );

      const {paymentLink} = response.data;
      if (paymentLink) {
        if(paymentLink?.link_url){
          openInNewTab(paymentLink?.link_url);
        }
      }
    } catch (error) {
      console.error('Error generating payment link:', error);
    }
  };

  const verifyReferralCode = async () => {
    try {
      const token = getSecureItem('token');

      const resp = await axios({
        url: `${BaseUrl}/company/validateReferralCode`,
        method: 'POST',
        data: {
          referralCode,
          companyId: getSecureItem('loggedinUserCompany')
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });

      // console.log("resp verifyReferralCode", JSON.stringify(resp.data));
      if (resp.data?.status) {
        toast.success("The entered referral code is correct.",{
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
        setTimeout(() => {
          if(`${getSecureItem("country")?.code}` == '91'){
            GeneratePayLinkForSub({planId: planData?.id, amount: planData?.discountedPrice, companyId: resp.data?.companyId})
          } else {
            setSecureItem(
              "pricingData",
              {
                priceId: planData?.id,
                price: planData?.discountedPrice,
              }
            );
            setModalValue({
              planName: planData?.name,
              originalPrice: planData?.originalPrice,
              discountedPrice: planData?.discountedPrice,
              tenure: tabValue
            });
            // setTimeout(() => {
              // history.push("/paymentSubscription");
              handleClickOpen();
            // }, 100);
          }
        }, 500);
      } else {
        toast.error(resp.data?.message,{
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }
    } catch (error) {
      console.log('error verifyReferralCode', JSON.stringify(error));
    }
  };

  const renderPlan = (plan) => (
    <Grid item xs={6} sm={6} md={3} key={plan.name}>
      <Box
        border={
          plan?.id == loggedinUser?.company?.planId
            ? "1px solid green"
            : "1px solid #e5e9fe"
        }
        borderRadius="5px"
        padding="10px"
      >
        <Stack
          alignItems="center"
          minHeight="300px"
          justifyContent="space-between"
        >
          <Stack alignItems="center" spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <img
                src={plan.image}
                alt={plan.name}
                loading="eager"
                width="13"
                height="15"
              />
              <Typography
                variant="body2"
                fontWeight="bold"
                fontFamily="monospace"
                className="Fonts"
              >
                {plan.name}
              </Typography>
            </Stack>
            {plan.id == 0 && trialActive && (
              <Chip
                label={`${trialDays} Days Left`}
                variant="outlined"
                color="error"
                size="small"
              />
            )}
            <Stack direction="row" spacing={1} alignItems="center">
              {/* <Typography
                fontFamily={"fantasy"}
                variant="h6"
                style={{
                  textDecorationLine: "line-through",
                  color: "#afafb3",
                }}
                className="Fonts"
              >
                {getCurrencySymbol(countryCurrency?.symbol)}
                {plan.originalPrice}
              </Typography> */}
              <Typography className="Fonts" variant="h5" fontFamily="fantasy">
                {getCurrencySymbol(countryCurrency?.symbol)}
                {plan.discountedPrice}
              </Typography>
              <Typography className="Fonts" variant="body2">/ {tabValue == "monthly" ? "month" : "year"}</Typography>
            </Stack>
          </Stack>
          {/* <Typography
            variant="button"
            fontFamily={"sans-serif"}
            className="Fonts"
            style={{
              padding: "5px",
              border: "1px solid #000",
              borderRadius: "5px",
              fontSize: "12px",
              fontWeight: 200,
              marginTop: 5
            }}
          >
            {plan?.centerBoxText}
          </Typography> */}
          <Stack marginBottom={2} spacing={0.7} marginTop="5px" alignItems="center">
            <Tooltip
              title={
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      color: "#fff",
                      margin: 0,
                      padding: 5,
                      lineHeight: 1.2,
                      fontSize: 16,
                    }}
                  >
                    1 User means only SuperAdmin
                  </p>
                  <p
                    style={{
                      color: "#fff",
                      margin: 0,
                      padding: 5,
                      lineHeight: 1.2,
                      fontSize: 16,
                    }}
                  >
                    3 Users means 1 SuperAdmin & 2 Admin/CollectionAgent
                  </p>
                  <p
                    style={{
                      color: "#fff",
                      margin: 0,
                      padding: 5,
                      lineHeight: 1.2,
                      fontSize: 16,
                    }}
                  >
                    5 Users means 1 SuperAdmin & 4 Admin/CollectionAgent
                  </p>
                  <p
                    style={{
                      color: "#fff",
                      margin: 0,
                      padding: 5,
                      lineHeight: 1.2,
                      fontSize: 16,
                    }}
                  >
                    Unlimited Users means 1 Super Admin and Unlimited
                    Admin/CollectionAgent
                  </p>
                </div>
              }
            >
              <Info />
            </Tooltip>
            {plan.features.map((feature, index) => (
              <Typography className="Fonts" key={index} variant="body1">
                {feature}
              </Typography>
            ))}
          </Stack>
          {plan?.id == loggedinUser?.company?.planId && (plan?.id == "0" ? (tabValue == "monthly" || tabValue == "yearly") : tabValue == tenure) ? (
            <Button
              className="muiCustomButton"
              variant="outlined"
              style={{
                color: "green",
                borderColor: "green",
                boxShadow: "inherit",
                fontWeight: "bold",
              }}
              endIcon={<ChevronRight />}
              disabled
            >
              ACTIVE
            </Button>
          ) : loggedinUser?.company?.planId != "0" ? (
            <Button
              className="muiCustomButton"
              variant="outlined"
              endIcon={<SupportAgent />}
              onClick={handleClickOpenSupport}
            >
              Contact Support
            </Button>
          ) : (
            <Button
              className="muiCustomButton"
              variant="outlined"
              endIcon={<ChevronRight />}
              onClick={() => {
                setOpenReferralModal(true);
                setPlanData(plan);
              }}
            >
              Buy Now
            </Button>
          )}
        </Stack>
      </Box>
    </Grid>
  );

  const yesCheck = () => (
    <Grid item xs={6} sm={6} md={3}>
      <Stack alignItems="center" justifyContent="space-between">
        <img
          src={"https://mui.com/static/branding/pricing/yes-light.svg"}
          alt="check"
          loading="eager"
        />
      </Stack>
    </Grid>
  );

  const noCheck = () => (
    <Grid item xs={6} sm={6} md={3}>
      <Stack alignItems="center" justifyContent="space-between">
        <img
          src={"https://mui.com/static/branding/pricing/no-light.svg"}
          alt="check"
          loading="eager"
        />
      </Stack>
    </Grid>
  );

  return (
    <>
      <Sidebar />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingInline={"100px"}
        paddingBlock={"10px"}
      >
        <img src={companylogo} alt="logo" />
      </Stack>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <PaymentSubscription modalValue={modalValue} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openSupport}
        onClose={handleCloseSupport}
        aria-labelledby="alert-dialog-title-support"
        aria-describedby="alert-dialog-description-support"
      >
        <Stack
          sx={{ padding: 2 }}
          spacing={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Button
            href="tel:8667016575"
            color="primary"
            startIcon={<Call fontSize="30" />}
          >
            Call Us
          </Button>
          <Button
            href="https://wa.me/918667016575?text=Hello,%20I%20would%20like%20to%20learn%20about%20LendStack."
            color="success"
            startIcon={<WhatsApp fontSize="30" />}
          >
            WhatsApp Us
          </Button>
          <Button
            href="mailto:cofounder@lendstack.app"
            color="error"
            startIcon={<Email fontSize="30" />}
          >
            Mail Us
          </Button>
        </Stack>
      </Dialog>

      <Dialog
        open={openReferralModal}
        onClose={() => setOpenReferralModal(false)}
        aria-labelledby="alert-dialog-title-support"
        aria-describedby="alert-dialog-description-support"
      >
        <Stack style={{padding: 20, width: "300px"}} spacing={3} alignItems="center">
        <Typography
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 20,
            fontFamily: "Lato",
            color: "#000",
          }}
        >
          Have a referral code?
        </Typography>

        <Typography
          style={{ fontSize: 17, marginTop: 30, color: "#000", fontFamily: "Lato", textAlign: "center" }}
        >
          Enter the referral code below and get 1-month extra free.
        </Typography>

        <TextField id="standard-basic" label="" value={referralCode} onChange={(e) => setreferralCode(e.target.value)} placeholder="Enter referral code..." />

          <Stack direction={"row"} alignItems="center" spacing={5} justifyContent={"space-between"}>
            <Button variant="outlined" onClick={() => {
              if(`${getSecureItem("country")?.code}` == '91'){
                GeneratePayLinkForSub({planId: planData?.id, amount: planData?.discountedPrice})
              } else {
                setSecureItem(
                  "pricingData",
                  {
                    priceId: planData?.id,
                    price: planData?.discountedPrice,
                  }
                );
                setModalValue({
                  planName: planData?.name,
                  originalPrice: planData?.originalPrice,
                  discountedPrice: planData?.discountedPrice,
                  tenure: tabValue
                });
                setTimeout(() => {
                  handleClickOpen();
                }, 100);
              }
            }}>Skip</Button>
            <Button variant="contained" color="success" onClick={() => referralCode?.length > 0 && verifyReferralCode()}>Continue</Button>
          </Stack>
        </Stack>
      </Dialog>

      <Container style={{ paddingBlock: "10px", paddingLeft: "100px" }}>
        <Typography
          variant="h1"
          textAlign={"center"}
          fontSize={50}
          fontWeight={700}
        >
          Pricing Plans
        </Typography>
        <Typography
          style={{ marginBlock: 10 }}
          textAlign={"center"}
          variant="body2"
          fontSize={"20px"}
        >
          We strive to provide the affordable and most feature rich
          <br />
          loan management solutions in the market
        </Typography>

        {/* Tabs for monthly/yearly Plans */}
        <Stack
          className="otherincomepage"
          spacing={0}
          padding={"0px 20px 0px 100px"}
        >
          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Tabs value={tabValue} onChange={tabsChange} centered>
              <Tab
                label={
                  <p className="Fonts" style={{ fontSize: 16 }}>
                    Monthly
                  </p>
                }
                value={"monthly"}
              />
              <Tab
                label={
                  <p className="Fonts" style={{ fontSize: 16 }}>
                    Yearly <span style={{ fontSize: 12 }}>(Save 2 months)</span>
                  </p>
                }
                value={"yearly"}
              />
            </Tabs>
          </Box>
        </Stack>

        {matches ? (
          <Grid container marginTop={2} spacing={2}>
            {plansData.map((plan) => renderPlan(plan))}
          </Grid>
        ) : (
          <>
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="primary tabs example"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                // variant="fullWidth"
              >
                <Tab value={"0"} label="Free" />
                <Tab value={"1"} label="Starter" />
                <Tab value={"2"} label="Business Plus" />
                <Tab value={"3"} label="Unlimited" />
              </Tabs>
            </Box>
            {plansData.map((plan) => plan.id == value && renderPlan(plan))}
          </>
        )}

        {value != 0 &&
          salientFeaturesData?.map((data) => (
            <Stack key={data?.name} marginTop={4}>
              <Box
                border={"1px solid #e5e9fe"}
                borderRadius={"10px"}
                padding={"10px"}
                boxShadow={1}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <img src={data?.img} alt="Heading" loading="eager" />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    fontFamily="monospace"
                  >
                    {data?.name}
                  </Typography>
                </Stack>
              </Box>
              {data?.subData?.map((item) => (
                <Stack key={item?.name}>
                  <Grid container spacing={4} paddingY={"15px"}>
                    <Grid item xs={6} sm={6} md={3} alignItems="center">
                      <Typography variant="body2" fontSize={15}>
                        {item?.name}
                      </Typography>
                    </Grid>
                    {matches
                      ? item?.data?.map((val) =>
                          val == "Yes" ? yesCheck() : noCheck()
                        )
                      : item?.data[parseInt(value) - 1] == "Yes"
                      ? yesCheck()
                      : noCheck()}
                  </Grid>
                  <Divider />
                </Stack>
              ))}
            </Stack>
          ))}

        {commonPlanData?.map((data) => (
          <Stack key={data?.name} marginTop={4}>
            <Box
              border={"1px solid #e5e9fe"}
              borderRadius={"10px"}
              padding={"10px"}
              boxShadow={1}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <img src={data?.img} alt="Heading" loading="eager" />
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  fontFamily="monospace"
                >
                  {data?.name}
                </Typography>
              </Stack>
            </Box>
            {data?.subData?.map((item) => (
              <Stack key={item?.name}>
                <Grid container spacing={4} paddingY={"15px"}>
                  <Grid item xs={6} sm={6} md={3} alignItems="center">
                    <Typography variant="body2" fontSize={15}>
                      {item?.name}
                    </Typography>
                  </Grid>
                  {yesCheck()}
                  {matches && yesCheck()}
                  {matches && yesCheck()}
                </Grid>
                <Divider />
              </Stack>
            ))}
          </Stack>
        ))}
      </Container>
    </>
  );
};

export default Pricing;

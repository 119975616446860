import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import userdefault from '../../assets/images/userdefault.svg';
import './editcollector.scss';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Sidebar from '../sidebar/sidebar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {BaseUrl, ImageBaseUrl} from "../../Environment";
import { withNamespaces } from 'react-i18next';
import { Select } from '@material-ui/core';
import { getSecureItem } from '../../utilsFunctions';
import store from '../GlobalState/store';
import { setSecretKey } from '../GlobalState/globalState';


const axios = require('axios')


class EditCollectorProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            countriesData: [],
            countryCode: `${getSecureItem("country")?.code}`,
            file: {},
            type:"",
            imagePreviewUrl: '',
            changeColorname: '1px solid #D4D4D5',
            changeColornumber: '1px solid #D4D4D5',
            changeColoremail: '1px solid #D4D4D5',
            changeColorcategory: '1px solid #D4D4D5',
            changeColorpassword: '1px solid #D4D4D5',
            category: "collectoion agent",
            fname:"",
            lname:"",
            mobilenum:"",
            emailid:"",
            password:"",
            validemail: true,
            emailError: "none",
            validnum: true,
            numError: "none",
            submitted: false
        };
        this.nameBox = this.nameBox.bind(this)
        this.numberBox = this.numberBox.bind(this)
        this.emailBox = this.emailBox.bind(this)
        this.categoryBox = this.categoryBox.bind(this)
        this.passwordBox = this.passwordBox.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validEmail = this.validEmail.bind(this)
        this.validNum = this.validNum.bind(this)
    }

    _handleSubmit(e) {
        e.preventDefault();
        // TODO: do something with -> this.state.file
    }

    _handleImageChange(e) {
        e.preventDefault();
        if(e.target.files[0].size > 1000000){
            toast.error("Image Size Should be less than 1 Mb", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                });         //    this.value = "";
         } else{

        let reader = new FileReader();
        let file = e.target.files[0];
        let tempName = file.name
        tempName = tempName.split('.');
        let name  = tempName[0] + Date.now() + '.' + tempName[1];
        let data;
         let temp = new File([file], name, {type: file.type})

        reader.onloadend = () => {
            this.setState({
                file: temp,
                filename:name,
                imagePreviewUrl: reader.result
            });
        }

        reader.readAsDataURL(file)
    }
    }
    handleChange = event => {
        this.setState({ category: event.target.value });
        // this.setState({ vehiclemodel: event.target.value })
    };
    nameBox() {
        this.setState({
            changeColorname: "1px solid #00D95E",
            changeColornumber: "1px solid #D4D4D5",
            changeColoremail: "1px solid #D4D4D5",
            changeColorcategory: "1px solid #D4D4D5",
            changeColorpassword: "1px solid #D4D4D5",

        })
    }
    numberBox() {
        this.setState({
            changeColornumber: "1px solid #00D95E",
            changeColorname: "1px solid #D4D4D5",
            changeColoremail: "1px solid #D4D4D5",
            changeColorcategory: "1px solid #D4D4D5",
            changeColorpassword: "1px solid #D4D4D5",

        })
    }
    emailBox() {
        this.setState({
            changeColoremail: "1px solid #00D95E",
            changeColornumber: "1px solid #D4D4D5",
            changeColorname: "1px solid #D4D4D5",
            changeColorcategory: "1px solid #D4D4D5",
            changeColorpassword: "1px solid #D4D4D5",

        })
    }
    passwordBox() {
        this.setState({
            changeColoremail: "1px solid #D4D4D5",
            changeColorpassword: "1px solid #00D95E",
            changeColornumber: "1px solid #D4D4D5",
            changeColorname: "1px solid #D4D4D5",
            changeColorcategory: "1px solid #D4D4D5"
        })
    }
    categoryBox() {
        this.setState({
            changeColorcategory: "1px solid #00D95E",
            changeColoremail: "1px solid #D4D4D5",
            changeColornumber: "1px solid #D4D4D5",
            changeColorname: "1px solid #D4D4D5",
            changeColorpassword: "1px solid #D4D4D5",

        })
    }
    updateUser(){
        let loggedinUser = getSecureItem("loggedinUser")

          const userdetails = {
               "firstName":this.state.fname,
               "empId":this.state.id,
               "lastName":this.state.lname,
               "userPhone":`+${this.state.countryCode}${this.state.mobilenum?.replace(/^\+`${this.state.countryCode}`/, '') }`,
               "userEmail":this.state.emailid,
               "password":this.state.password,
               "userId": this.props.location.params.userId,
               "createdAt":this.props.location.params.createdAt,
               "updatedAt":this.props.location.params.updatedAt,
               "userType":this.state.category,
               "isActive":true,
               "companyId": loggedinUser.companyId
            }
            
        let formData = new FormData();
        formData.append('alldetails', JSON.stringify(userdetails)); 
        formData.append("userprofilepic", this.state.file);
        
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ getSecureItem("token")
        }
        axios.put(BaseUrl + '/user/updateUser',formData,{
            headers: headers,
        }).then(resp => {
            if(resp.data?.status) {
                toast.success(resp.data?.message, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    });
                this.props.history.push('/usermanagement')      
            }else{
                toast.error(resp.data?.message, {
                    position: "top-center",
                    autoClose: 10000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    });
            }
        }).catch(error => {
            if (error.request.status === 401) {               
                if (error.response.data.message === "User already exists with the above Email ID") {
                    toast.error(error.response.data.message, {
                        position: "top-center",
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                } else {
                    toast.error(error.response.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });

                }
            }
            
        })
    }
      backLinkAction() {
        this.props.history.push('/usermanagement')      
      }

      getAllCountries = async () => {
        try {
          const response = await axios.get(BaseUrl + "/country/getAllCountries");
          const lastElement = response.data?.pop();
          this.setState({
            countriesData: response.data,
          });
          store.dispatch(setSecretKey(lastElement));
        } catch (error) {
          console.log({ error });
        }
      };

      componentDidMount(){
        this.getAllCountries();

          if(this.props.location.params){
            let countryCode = `+${this.state.countryCode}`;
            let mobileNumber = this.props.location.params.userPhone;

            // Construct the regex pattern using the country code
            let regex = new RegExp('^\\+' + countryCode.slice(1) + '\\s*');
            // Remove the country code from the mobile number
            let cleanedNumber = mobileNumber.replace(regex, '');

          this.setState({
            id: this.props.location.params?.empId,
            fname: this.props.location.params.firstName,
            lname:this.props.location.params.lastName,
            mobilenum: cleanedNumber,
            emailid: this.props.location.params.userEmail,
            category:this.props.location.params.userType,
            imagePreviewUrl:this.props.location.params.userImagePath? ImageBaseUrl + this.props.location.params.userImagePath:null
          })
        } else {
            this.setState({
            fname: "",
            lname:"",
            mobilenum: "",
            emailid: "",
            category:"",
            imagePreviewUrl:""
        })
        }
      }
      handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { fname,lname,  category, emailid, mobilenum, file } = this.state;
        if (fname && lname && category && emailid && mobilenum && file) {
            // this.props.login(username, password);
            this.updateUser();
        }
    }
    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
        return re.test(email)
    }
    validEmail = (event) => {
        const emailid = event.target.value
        const emailVaild = this.validateEmail(emailid)
        this.setState({
            emailid: event.target.value,
            validEmail: emailVaild
        })
        if (emailVaild === false) {
            this.setState({
                emailError: "flex"
            })
        } else {
            this.setState({
                emailError: "none"
            })
        }
    }
    validateNum(mobilenum) {
        const re = /^\d{10}$/g
        return re.test(mobilenum)
    }
    validNum = (event) => {
        // event.target.value = event.target.value.replace(/^[0-9\b]+$/)
        const mobilenum = event.target.value
        const numVaild = this.validateNum(mobilenum)
        this.setState({
            mobilenum: event.target.value,
            validNum: numVaild
        })
        if (numVaild === false) {
            this.setState({
                numError: "flex"
            })
        } else {
            this.setState({
                numError: "none"
            })
        }
    }

    render(){

        console.log(this.props.location.params);
        const { t } = this.props;

        let file=this.state.file;
        file.fieldname="userprofilepic";

        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (<img src={imagePreviewUrl} alt="profile" />);
        } else {
            $imagePreview = (<div className="previewText">
                <img src={userdefault} alt="userdefault" className="iconButton" style={{width:"75px"}} />
            </div>);
        }
        const Category = [
            { value: 'Admin', label: 'Admin' },
            { value: 'CollectionAgent', label: 'Collection Agent', },
            { value: 'Employee', label: 'Employee', },
        ];
        const { fname, lname, countriesData, countryCode, category, emailid, mobilenum, submitted, id } = this.state;

        return(
            <div><Sidebar/>
                <div style={{textAlign:'center', marginTop: '15px'}}>
                {/* <div style={{width:'40%'}}>
                <img src={backbuttn} alt="backicon" style={{paddingLeft: '50px',paddingTop: '23px', cursor:'pointer'}} onClick={this.back.bind(this)} />
                </div> */}
                <div>
                  <h3 className="Fonts headFontSize" style={{marginLeft:'45px'}}><span className="backLink" onClick={this.backLinkAction.bind(this)}>{t('UserManagement.title')}</span> / {t('CompanyProfile.subtitle')}</h3>
                </div>
              </div>
            <div className="editcollectorcomponent">
                <Card className="cardDiv">
                    <div className="previewComponent">
                        <form onSubmit={(e) => this._handleSubmit(e)}>
                            <div style={{ position: "relative" }}>
                                <div className="imgPreview">
                                    {$imagePreview}
                                    <input className="fileInput"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => this._handleImageChange(e)} 
                                        id="icon-button-file"
                                        />
                                </div>
                                {/* <img src={cameradefault} alt="cameradefault" className="cameradefault" /> */}
                                <label htmlFor="icon-button-file">
                                    <IconButton style={{color:"#00D95E"}} aria-label="upload picture" component="span" className="cameradefault">
                                      <PhotoCamera />
                                    </IconButton>
                                    </label>
                            </div>
                            
                            <div style={{ margin: 'auto', textAlign: "center" }}>
                                <div className="textFieldStyle">
                                    <h6 className="InputLabel One Fonts fontSize" style={{ marginLeft: "16px" }}>{"Employee ID"}</h6>
                                    <div className={'form-group' + (submitted && !id ? ' has-error' : '')} style={{marginTop: '10px'}}>
                                    <Input className="dynamicWidth textBox form-control" placeholder="Enter Employee ID" required name="id" style={{ height: '38px', border: "1px solid #00D95E" }} value={id} onChange={(e) => this.setState({ id: e.target.value })} />
                                    {submitted && !id &&
                                    <div className="help-block-user">ID is required</div>
                                    }
                                    </div>
                                </div>
                                <div className="textFieldStyle">
                                    <h6 className="InputLabel Fonts SizeFont" style={{ marginLeft: "16px" }}>{t('EditProfileDetails.fname')}</h6>
                                    <div className={'form-group' + (submitted && !fname ? ' has-error' : '')} style={{marginTop: '10px'}}>
                                    <Input className="dynamicWidth textBox" placeholder="Given Name / Middle Name" value={this.state.fname} style={{ height: '38px', border: this.state.changeColorname }} onClick={this.nameBox} onChange={(event) =>{  event.target.value = event.target.value.replace(/[^A-Za-z]/ig, '');  this.setState({ fname: event.target.value })}} />
                                    {submitted && !fname &&
                                        <div className="help-block-user">First Name is required</div>
                                        }
                                        </div>
                                </div>
                                <div className="textFieldStyle">
                                    <h6 className="InputLabel Fonts SizeFont" style={{ marginLeft: "16px" }}> {t('EditProfileDetails.lname')}</h6>
                                    <div className={'form-group' + (submitted && !lname ? ' has-error' : '')} style={{marginTop: '10px'}}>
                                    <Input className="dynamicWidth textBox" placeholder="Surname / Family Name" value={this.state.lname} style={{ height: '38px', border: this.state.changeColorname }} onClick={this.nameBox} onChange={(event) =>{  event.target.value = event.target.value.replace(/[^A-Za-z]/ig, '');  this.setState({ lname: event.target.value })}} />
                                    {submitted && !lname &&
                                        <div className="help-block-user"> Last Name is required</div>
                                        }
                                        </div>
                                </div>
                                <div className="numaricTextField">
                                    <h6 className="InputLabel Fonts SizeFont" style={{ marginLeft: "30px" }}> {t('EditProfileDetails.phone')}</h6>
                                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} className={'form-group' + (submitted && !mobilenum ? ' has-error' : '')}>
                                            <Select value={countryCode} required style={{ width: "100px", height: "38px", border: this.state.changeColorname, borderRight: "none", borderRadius: "unset", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px"}} onClick={this.categoryBox}
                                                onChange={(e) => this.setState({countryCode: e.target.value})} >
                                                {countriesData?.map((option, i) => (
                                                    <MenuItem key={i} value={option?.code}>
                                                        {"+"+option?.code}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <Input type="number" placeholder="mobile number" value={this.state.mobilenum}  required className="numberInputW textBox form-control" name="fname" style={{ height: '38px', border: this.state.changeColorname, borderLeft: "none", borderRadius: "unset", borderTopRightRadius: "5px", borderBottomRightRadius: "5px"}}  onClick={this.numberBox} onChange={this.validNum} />
                                            </div>
                                    {submitted && !mobilenum &&
                                        <span className="help-block-user">Mobile Number is required</span>
                                        }
                                </div>
                                <span style={{fontSize:"11px", fontFamily: 'Lato'}}>Note:Please provide Correct Mobile Number for User to Login.</span>
                                <div className="textFieldStyle">
                                    <h6 className="InputLabel Fonts SizeFont" style={{ marginLeft: "9px" }}>{t('EditProfileDetails.email')}</h6>
                                    <div className={'form-group' + (submitted && !emailid ? ' has-error' : '')} style={{marginTop: '10px'}}>
                                    <Input className="dynamicWidth textBox" placeholder="smith@example.com" value={this.state.emailid} style={{ height: '38px', border: this.state.changeColoremail }} onClick={this.emailBox} onChange={this.validEmail} />
                                    <span style={{ display: this.state.emailError}} className="help-block">Invalid Email ID.</span>                                       
                                        {submitted && !emailid &&
                                        <div className="help-block-user"  style={{display:this.state.reqemail}}>Email ID is required</div>
                                        }
                                        </div>
                                </div>
                                <span style={{fontSize:"11px", fontFamily: 'Lato'}}>Note:Please provide Correct Email-Id for User to Login.</span>
                                {/* <div className="textFieldStyle">
                                        <h6 className="InputLabel Fonts SizeFont" style={{ marginLeft: "9px" }}>Password</h6>
                                        <div className={'form-group' + (submitted && !password ? ' has-error' : '')} style={{marginTop: '10px'}}>
                                        <Input className="textBox" type="password" required className="form-control" name="password" style={{ height: '38px', border: this.state.changeColorpassword }} onClick={this.passwordBox} onChange={(event) => this.setState({ password: event.target.value })} />
                                        {submitted && !password &&
                                        <div className="help-block-user">Password is required</div>
                                        }
                                        </div>
                                    </div> */}
                                <div className="categorytextFieldStyle" >
                                    <h6 className="InputLabel Fonts SizeFont" style={{ marginLeft: "10px" }}> {t('AddUser.Cat')}</h6>
                                    <div className={'form-group' + (submitted && !category ? ' has-error' : '')} style={{marginTop: '10px'}}>
                                    <TextField id="standard-select" select value={this.state.category?this.state.category: 'Collection Agent'} className="dynamicWidth textBox" style={{ width: "29%", marginLeft: "0%", borderRadius: '5px', border: this.state.changeColorcategory }} onClick={this.categoryBox}
                                        onChange={this.handleChange} >
                                        {Category.map(option => (
                                            <MenuItem key={option.label} value={option.label}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {submitted && !category &&
                                        <div className="help-block-user">Category is required</div>
                                        }
                                        </div>
                                </div>
                                <div>
                                    <Button  className="cancelbutton btnSizeFont Fonts" onClick={() => this.props.history.push("/usermanagement")}>
                                            cancel
                                    </Button>
                                        <Button  className="savebutton btnSizeFont Fonts" onClick={this.handleSubmit.bind(this)}>
                                            Save
                                        </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Card>
            </div>
        </div>
        )
    }
}
export default withNamespaces()(EditCollectorProfile);
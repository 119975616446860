import "../login.scss";
import "react-toastify/dist/ReactToastify.css";

import React, { Component } from "react";
import {
  Card,
  Grid,
  Checkbox,
  FormGroup,
  FormControl,
  Input,
  FormControlLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import loginimage from "../../../assets/images/loginimage.svg";
import GooglePlay from "../../../assets/images/Group 28315.svg";
import AppStore from "../../../assets/images/Group 28316.svg";
import { BaseUrl } from "../../../Environment";
import { auth, provider } from "../../../firebase/config";
import LoadingButton from "@mui/lab/LoadingButton";
import GoogleIcon from "@mui/icons-material/Google";
import {
  updateSessionUser,
  getUserDetails,
  getCompanyProfile,
} from "../../../api";
import { getUserTypeDetails, getAllSettings, getSecureItem, setSecureItem } from "../../../utilsFunctions";

const axios = require("axios");
class SignUp1 extends Component {
  constructor() {
    super();
    this.state = {
      lname: "",
      fname: "",
      type: "",
      emailid: "",
      password: "",
      confirmpassword: "",
      curTime: "",
      mobilenum: "",
      submitted: false,
      emailError: "none",
      validemail: true,
      validnum: true,
      numError: "none",
      checkedA: false,
    };
  }

  validateEmail(emailid) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    return re.test(emailid);
  }
  validEmail = (event) => {
    const emailid = event.target.value;
    const emailVaild = this.validateEmail(emailid);
    this.setState({
      emailid: event.target.value,
      validEmail: emailVaild,
    });
    if (emailVaild === false) {
      this.setState({
        emailError: "flex",
      });
    } else {
      this.setState({
        emailError: "none",
      });
    }
  };
  validateNum(mobilenum) {
    const re = /^\d{10}$/g;
    return re.test(mobilenum);
  }
  validNum = (event) => {
    const country = getSecureItem("country");
    const mobilenum = event.target.value;
    const numVaild = this.validateNum(mobilenum);
    this.setState({
      mobilenum: event.target.value,
      validNum: country?.code == "91" ? numVaild : true,
    });
    if (numVaild === false) {
      this.setState({
        numError: "flex",
      });
    } else {
      this.setState({
        numError: "none",
      });
    }
  };
  Termscheck = (event) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    console.log("checkedA", event.target.checked);
    if (this.state.checkedA === false) {
      this.setState({
        checkedA: true,
      });
    } else {
      this.setState({
        checkedA: false,
      });
    }
  };

  companyData = () => {
    getCompanyProfile()
      .then((resp) => {
        if (resp) {
          console.log("companyData response is:", resp);
          setSecureItem("companyCountry", resp.country);
        }
      })
      .catch((err) => {
        // if (err.request.status !== 200) {
        console.log({ err });
        // }
      });
  };

  handleSubmit = () => {
    const { fname, lname, emailid, checkedA, mobilenum } = this.state;

    if (fname != "" && lname != "" && emailid != "" && mobilenum != "") {
      try {
        const userTypeAY = getSecureItem("userTypeAY");

        const userData = {
          firstName: fname,
          lastName: lname,
          userEmail: emailid,
          userPhone: mobilenum?.startsWith("+") ? mobilenum : `+${getSecureItem("country")?.code}${mobilenum}`,
          userType: userTypeAY,
          planId: "0",
          planPrice: 0,
          trialActive: userTypeAY == "borrower" ? false : true,
          isActive: true,
          isVerified: true,
          agreement: checkedA,
          countryId: getSecureItem("country")?.countryId,
        };
        updateSessionUser(userData).then((res) => {
          if (res) {
            getUserDetails(
              getSecureItem("firebaseUid")
            ).then(async (dbUser) => {
              if (dbUser.userId && dbUser.firstName && dbUser.lastName) {
                setSecureItem("userid", dbUser.userId);
                setSecureItem("loggedinUser", dbUser);
                setSecureItem("loggedinUserCompany",dbUser.companyId);
                
                getUserTypeDetails(dbUser?.userType, dbUser?.companyId,dbUser?.userId);
                getAllSettings();
                this.companyData();
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              }
            });
          } else {
            this.props.history.push("/signup/register");
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  componentDidMount() {
    const email = getSecureItem("defaultEmail");
    const phone = getSecureItem("defaultPhone");

    this.setState({
      mobilenum: phone ? phone : "",
      validNum: phone ? true : false,
      emailid: email ? email : "",
    });
  }

  render() {
    toast.configure({});
    const {
      lname,
      fname,
      emailid,
      mobilenum,
      submitted,
      checkedA,
      validemail,
      validnum,
    } = this.state;

    const defaultEmail = getSecureItem("defaultEmail");
    const defaultPhone = getSecureItem("defaultPhone");
    const country = getSecureItem("country");

    return (
      <Grid container className="loginPage dBlockCss">
        <Grid item md={6} className="paddingLoginGrids">
          <div>
            <div className="firstH3 TextColour">Collect like a PRO!</div>
            <div className="firstH4 TextColour">
              Digital Bharat ka Digital Khata
            </div>
          </div>
          <div>
            <div>
              <img src={loginimage} alt="login" className="loginIMage" />
              <div className="secondH4">
                Digital Ledger Account (Udhar Bahi Khata book) for Credit,
                Lending and Hisab Khitab for small lenders, grocers and
                shopkeepers
              </div>
              <div className="storeButtons">
                <div className="storeButtonsOne">
                  <a href="https://play.google.com/store/apps/details?id=com.lendstack" target="_blank" className="googlePlay">
                    <img
                      src={GooglePlay}
                      alt="googlePlay"
                      className="googlePlay"
                    />
                  </a>
                  <div className="appStore">
                    <img src={AppStore} alt="appStore" className="appStore" />
                  </div>
                </div>
                <div className="clickHere">
                  Read our{" "}
                  <a
                    target="_blank"
                    href="https://lendstack.app/#/termsofservice"
                    style={{ textDecoration: "none" }}
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    href="https://lendstack.app/#/privacypolicy"
                    style={{ textDecoration: "none" }}
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={6} className="paddingLoginGrids">
          <Card className="CardDivLogin">
            <div className="CardDiv4">
              <div className="CardDiv5 HighlightTextColour">
                <h3 className="Fonts">Let's set up your Lendstack account</h3>
              </div>

              <form>
                <div
                  className={
                    "form-group" + (submitted && !fname ? " has-error" : "")
                  }
                  style={{ marginTop: "10px" }}
                >
                  <Input
                    placeholder="First Name"
                    required
                    name="fname"
                    className="form-control inputBoxContent Fonts SizeFont"
                    fullWidth
                    onChange={(event) =>
                      this.setState({ fname: event.target.value })
                    }
                  />
                  {submitted && !fname && (
                    <div className="help-block"> First name is required</div>
                  )}
                </div>
                <div
                  className={
                    "form-group" + (submitted && !lname ? " has-error" : "")
                  }
                  style={{ marginTop: "10px" }}
                >
                  <Input
                    placeholder="Last Name"
                    required
                    name="lname"
                    className="form-control inputBoxContent Fonts SizeFont"
                    fullWidth
                    onChange={(event) =>
                      this.setState({ lname: event.target.value })
                    }
                  />
                  {submitted && !lname && (
                    <div className="help-block">Last name is required</div>
                  )}
                </div>
                <div
                  className={
                    "form-group" + (submitted && !emailid ? " has-error" : "")
                  }
                  style={{ marginTop: "10px" }}
                >
                  <Input
                    type="email"
                    placeholder="Email"
                    required
                    name="emailid"
                    value={emailid}
                    disabled={defaultEmail ? true : false}
                    className="form-control inputBoxContent Fonts SizeFont"
                    fullWidth
                    /*  onChange={(event) => this.setState({ email: event.target.value })} */ onChange={
                      this.validEmail
                    }
                  />
                  <span
                    style={{ display: this.state.emailError }}
                    className="help-block"
                  >
                    Invalid Email ID.
                  </span>
                  {submitted && !emailid && (
                    <div
                      className="help-block"
                      style={{ marginRight: "14rem" }}
                    >
                      Email ID is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "form-group" + (submitted && !mobilenum ? " has-error" : "")
                  }
                  style={{ marginTop: "10px" }}
                >
                  <Input
                    type={mobilenum !== "" ? "text" : "number"}
                    placeholder="Enter Mobile Number without prefixes"
                    required
                    name="mobilenum"
                    value={mobilenum}
                    disabled={defaultPhone ? true : false}
                    className="form-control inputBoxContent Fonts SizeFont"
                    fullWidth
                    /* onChange={(event) => this.setState({ phone: event.target.value })} */ onChange={
                      this.validNum
                    }
                  />
                  {country?.code == "91" && <span
                    style={{ display: this.state.numError }}
                    className="help-block"
                  >
                    Mobile Number must be 10 digit.
                  </span>}
                  {submitted && !mobilenum && (
                    <div
                      className="help-block"
                      style={{ marginRight: "12rem" }}
                    >
                      Mobile Number is required
                    </div>
                  )}
                </div>

                <FormGroup row>
                  <FormControlLabel
                  style={{width: "100%"}}
                    control={
                      <Checkbox
                        checked={this.state.checkedA}
                        onChange={this.Termscheck}
                        name="checkedA"
                        // required
                      />
                    }
                    label={
                      <div>
                        {" "}
                        I agree to the{" "}
                        <a
                          target="_blank"
                          href="https://lendstack.app/#/termsofservice"
                          style={{ textDecoration: "none" }}
                        >
                          Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                          target="_blank"
                          href="https://lendstack.app/#/privacypolicy"
                          style={{ textDecoration: "none" }}
                        >
                          Privacy Policy
                        </a>{" "}
                        of Lendstack<span style={{fontSize: 20}}>*</span>
                      </div>
                    }
                  />
                  {submitted && !checkedA && (
                    <div className="help-block">
                      Please indicate that you agree to the "Terms of Service"
                      and "Privacy Policy" of Lendstack
                    </div>
                  )}
                </FormGroup>
              </form>
              <div className="btnDiv">
                {/* onClick={this.handleSubmit} */}
                <LoadingButton
                  variant="outlined"
                  fullWidth
                  className="Fonts btnSizeFont btn"
                  onClick={this.handleSubmit}
                >
                  Next
                </LoadingButton>
                {/* <button onClick={this.notify}>Notify !</button> */}
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
export default SignUp1;

import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Paper,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import { DeleteSharp, EditSharp } from "@mui/icons-material";
import { toast } from "react-toastify";
import Sidebar from "../sidebar/sidebar";
import { companylogo } from "../../assets/images";
import {
  getAllOtherIncomeTypeAPI,
  addOtherIncomeTypeAPI,
  updateOtherIncomeTypeAPI,
  deleteOtherIncomeTypeAPI,
} from "../../service/service";
import "react-toastify/dist/ReactToastify.css";
import "./OtherIncome.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getSecureItem } from "../../utilsFunctions";

const OtherIncomeTypes = () => {
  const history = useHistory();
  const loggedinUser = getSecureItem("loggedinUser");

  const otherIncomeStaticTypes = [
    { label: "Business Income", value: null },
    { label: "Capital Gains", value: null },
    { label: "Collateral Sale", value: null },
    { label: "Interest Received", value: null },
    { label: "Motor Vehicle Sale", value: null },
    { label: "Passive Activities", value: null },
    { label: "Rental Income", value: null },
  ];

  const formattedIncomeTypes = otherIncomeStaticTypes.map((item) => ({
    typeId: item?.value,
    title: item?.label,
    description: "",
    createdByUserId: null,
    updatedByUserId: null,
    companyId: null,
    createdAt: "",
    updatedAt: "",
  }));

  const filterArrays = (arr1, arr2) => {
    // Combine both arrays
    const combinedArr = [...arr1, ...arr2];

    // Group by label
    const groupedByLabel = combinedArr?.reduce((acc, current) => {
      acc[current.title] = acc[current.title] || [];
      acc[current.title].push(current);
      return acc;
    }, {});

    // Filter out groups that contain both null and non-null values for the same label
    const filteredArr = Object.values(groupedByLabel).reduce((acc, group) => {
      const hasNull = group.some((item) => item.typeId == null);
      const hasNonNull = group.some((item) => item.typeId != null);

      if (hasNull && hasNonNull) {
        // Exclude only null typeIds if there are non-null typeIds for the same label
        const nonNullGroup = group.filter((item) => item.typeId != null);
        acc.push(...nonNullGroup);
      } else {
        // Include the whole group as is
        acc.push(...group);
      }

      return acc;
    }, []);

    return [...new Set(filteredArr)];
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    time: 2000,
    variant: "success",
  });

  const [payload, setPayload] = useState({
    title: "",
    description: "",
  });

  const [errors, setErrors] = useState({
    title: "",
  });

  const [typeId, setTypeId] = useState(null);
  const [otherIncomeTypeData, setOtherIncomeTypeData] = useState([]);
  const [deleteOtherIncomeTypeModal, setDeleteOtherIncomeTypeModal] =
    useState(false);
  const [addUpdateModal, setAddUpdateModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const fetchData = async () => {
    try {
      const companyId = loggedinUser?.companyId;

      const otherincomeTypesRes = await getAllOtherIncomeTypeAPI(companyId);

      if (otherincomeTypesRes?.status == 200 && otherincomeTypesRes?.data) {
        setOtherIncomeTypeData(
          filterArrays(otherincomeTypesRes?.data, formattedIncomeTypes)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }));
  };

  const handleSaveOtherIncomeType = async () => {
    const newErrors = {
      title: !payload?.title ? "This field is required" : "",
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error != "");

    if (!hasErrors) {
      const userId = loggedinUser?.userId;
      const companyId = loggedinUser?.companyId;

      try {
        const otherIncomePayload = {
          ...payload,
          companyId,
          createdByUserId: userId,
          updatedByUserId: userId,
        };

        const res = isUpdate
          ? await updateOtherIncomeTypeAPI({ ...otherIncomePayload, typeId })
          : await addOtherIncomeTypeAPI(otherIncomePayload);

        if (res.data?.status) {
          setAddUpdateModal(false);
          setToastMsg({
            msg: `Income Type ${isUpdate ? "Updated" : "Added"} Successfully.`,
            time: 3000,
            variant: "success",
          });

          setTimeout(() => {
            handleOpen();
          }, 500);
          
          setTimeout(() => fetchData(), 1000);
        }  else {
          setAddUpdateModal(false);
          setToastMsg({
            msg: `${res.data?.msg}`,
            time: 3000,
            variant: "info",
          });

          setTimeout(() => {
            handleOpen();
          }, 500);

          setTimeout(() => fetchData(), 1000);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSaveNull = async (data) => {
    const userId = loggedinUser?.userId;
    const companyId = loggedinUser?.companyId;

    try {
      const otherIncomePayload = {
        title: data?.title,
        description: "",
        companyId,
        createdByUserId: userId,
        updatedByUserId: userId,
      };

      const res = await addOtherIncomeTypeAPI(otherIncomePayload);

      if (res?.status == 200) {
        handleEdit(res?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (data) => {
    setPayload({
      title: data?.title,
      description: data?.description,
    });
    setTypeId(data.typeId);
    setIsUpdate(true);
    setAddUpdateModal(true);
  };

  const handleDelete = async () => {
    try {
      const resp = await deleteOtherIncomeTypeAPI(typeId);

      if (resp.request.status == 422) {
        setToastMsg({
          msg: "This income type already has transactions.",
          time: 4000,
          variant: "info",
        });

        setTimeout(() => {
          handleOpen();
        }, 500);
      } else {
        setToastMsg({
          msg: "Income Type Deleted Successfully.",
          time: 3000,
          variant: "success",
        });

        setTimeout(() => {
          handleOpen();
        }, 500);
      }

      setDeleteOtherIncomeTypeModal(false);
      setTimeout(() => {
        fetchData();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Sidebar />

      <Snackbar
        open={open}
        autoHideDuration={toastMsg.time}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={toastMsg.variant}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMsg.msg}
        </Alert>
      </Snackbar>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"10px 100px"}
      >
        <img src={companylogo} alt="logo" />
      </Stack>

      <Stack
        className="otherincomepage"
        spacing={1}
        padding={"10px 20px 10px 100px"}
      >
        <Grid container>
          <Grid item md={6} sm={4}></Grid>
          <Grid item md={3} sm={4} sx={{ padding: "0 10px" }}>
            <Button
              className="muiCustomButton"
              variant="outlined"
              onClick={() => history.push("otherincome")}
            >
              View Other Income
            </Button>
          </Grid>
          <Grid item md={3} sm={4} sx={{ padding: "0 10px" }}>
            <Button
              className="GreenBTN"
              variant="filled"
              onClick={() => {
                setPayload({
                  title: "",
                  description: "",
                });
                setTypeId(null);
                setIsUpdate(false);
                setAddUpdateModal(true);
              }}
            >
              + Add Income Type
            </Button>
          </Grid>
        </Grid>

        <Card style={{ marginTop: "10px" }}>
          <div className="tableLaylout">
            <Table aria-label="sticky table" className="table">
              <TableHead>
                <TableRow className="tablHeadRowPayment">
                  <TableCell className="tablHeadCellPayment">Title</TableCell>
                  <TableCell className="tablHeadCellPayment">
                    Description
                  </TableCell>
                  <TableCell className="tablHeadCellPayment">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {otherIncomeTypeData?.map((data, i) => {
                  const { title, description, typeId } = data;

                  return (
                    <TableRow key={i} className="tableRow">
                      <TableCell>{title}</TableCell>
                      <TableCell>{description}</TableCell>
                      <TableCell>
                        <Stack direction={"row"} spacing={1}>
                          <IconButton
                            color="primary"
                            aria-label="update"
                            onClick={() =>
                              typeId ? handleEdit(data) : handleSaveNull(data)
                            }
                          >
                            <EditSharp />
                          </IconButton>
                          {typeId ? (
                            <IconButton
                              color="error"
                              aria-label="delete"
                              onClick={() => {
                                setTypeId(typeId);
                                setDeleteOtherIncomeTypeModal(true);
                              }}
                            >
                              <DeleteSharp />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="error"
                              aria-label="delete"
                              disabled={true}
                              onClick={() => {
                                setTypeId(typeId);
                                setDeleteOtherIncomeTypeModal(true);
                              }}
                            >
                              <DeleteSharp />
                            </IconButton>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </Card>
      </Stack>

      {/* Delete Other Income Modal */}
      <Dialog
        open={deleteOtherIncomeTypeModal}
        onClose={() => setDeleteOtherIncomeTypeModal(false)}
      >
        <DialogTitle>Delete Other Income Type</DialogTitle>
        <DialogContent>
          <Typography fontSize={15} variant="h6">
            Are you sure, You want to delete this Other Income type record?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <Button
              variant="outlined"
              onClick={() => setDeleteOtherIncomeTypeModal(false)}
            >
              Cancel
            </Button>
            <Button color="error" variant="contained" onClick={handleDelete}>
              Delete
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      {/* Add/Update Modal */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={addUpdateModal}
        onClose={() => setAddUpdateModal(false)}
      >
        <DialogTitle>
          {isUpdate ? "Update" : "Add"} Other Income Type
        </DialogTitle>
        <DialogContent>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Paper elevation={3} sx={{ p: 2, width: "100%" }}>
              <Stack spacing={0.5} marginBottom={1}>
                <Typography variant="body1">
                  Title<span style={{ color: "red", fontSize: 15 }}>*</span>
                </Typography>
                <TextField
                  hiddenLabel
                  value={payload.title}
                  placeholder="Enter Title"
                  onChange={(e) => handleChange("title", e.target.value)}
                  variant="outlined"
                  fullWidth
                />
                {errors.title && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {errors.title}
                  </Typography>
                )}
              </Stack>
              <Stack spacing={0.5} marginBottom={1}>
                <Typography variant="body1">Description</Typography>
                <TextField
                  hiddenLabel
                  value={payload.description}
                  placeholder="Enter Description"
                  onChange={(e) => handleChange("description", e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Stack>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <Button variant="outlined" onClick={() => setAddUpdateModal(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSaveOtherIncomeType}>
              {isUpdate ? "Update" : "Add"}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OtherIncomeTypes;

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  IconButton,
  ButtonGroup,
  Typography,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import refer3 from "../../../assets/images/refer3.png";
import { getSecureItem, setSecureItem } from "../../../utilsFunctions";
import axios from "axios";
import { BaseUrl } from "../../../Environment";
import { MailOutline, WhatsApp } from "@mui/icons-material";

const ReferralPopup = ({ open, close }) => {
  const [copied, setCopied] = useState(false);
  const [referralCode, setReferralCode] = React.useState("");

  const generateReferralCode = async () => {
    try {
      const token = getSecureItem("token");
      const companyId = getSecureItem("loggedinUserCompany");

      const resp = await axios({
        url: `${BaseUrl}/company/generateReferralCode`,
        method: "PUT",
        data: {
          companyId,
          percentage: 30,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      // console.log("resp generateReferralCode", JSON.stringify(resp.data));
      if (resp.data?.message == "Referral code generated successfully") {
        // Update local state
        setReferralCode(resp.data?.referralCode);
        setSecureItem("referralCode", resp.data?.referralCode);
      } else {
        // Update local state
        setReferralCode(resp.data?.referralCode);
        setSecureItem("referralCode", resp.data?.referralCode);
      }
    } catch (error) {
      console.log("error generateReferralCode", error);
    }
  };

  useEffect(() => {
    if (getSecureItem("referralCode")) {
      setReferralCode(getSecureItem("referralCode"));
    } else {
      generateReferralCode();
    }
  }, []);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied status after 2s
  };

  const shareViaWhatsApp = () => {
    const message = encodeURIComponent(
      `I highly recommend LendStack for hassle-free loan management! Use my referral code ${referralCode} while purchasing a plan and get 1-month extra free. Download here:\nhttps://play.google.com/store/apps/details?id=com.lendstack`
    );
    window.open(`https://wa.me/?text=${message}`, "_blank");
  };

  const shareViaEmail = () => {
    const subject = encodeURIComponent("Use my referral & save more...");
    const body = encodeURIComponent(
      `I highly recommend LendStack for hassle-free loan management! Use my referral code ${referralCode} while purchasing a plan and get 1-month extra free. Download here:\nhttps://play.google.com/store/apps/details?id=com.lendstack`
    );
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogContent sx={{ position: "relative", padding: 0 }}>
        {/* Close Button */}
        <IconButton
          onClick={close}
          sx={{ position: "absolute", top: 5, right: 5 }}
        >
          <CloseIcon />
        </IconButton>

        {/* Popup Card */}
        <Card sx={{ width: 450, borderRadius: 0, textAlign: "center" }}>
          <CardContent>
            <Typography
              sx={{ marginBottom: 2, fontSize: 20, flexFlow: "Lato" }}
            >
              Refer your friends & earn 30% of their subscription amount
              instantly!
            </Typography>
            <img
              src={refer3} // Replace with your image
              alt="Referral"
              style={{ width: "100%", borderRadius: 10, marginBottom: 15 }}
            />

            {/* Referral Code Input */}
            <Typography
              variant="h6"
              sx={{ textAlign: "center", fontFamily: "Lato" }}
            >
              Referral Code
            </Typography>
            <ButtonGroup variant="outlined" aria-label="button group">
              <Button style={{ fontFamily: "Lato" }} variant="contained">
                {referralCode}
              </Button>
              <CopyToClipboard text={referralCode} onCopy={handleCopy}>
                <Tooltip title={copied ? "Copied!" : "Copy Code"}>
                  <Button
                    style={{ fontFamily: "Lato" }}
                    color={copied ? "success" : "primary"}
                    startIcon={<ContentCopyIcon />}
                  >
                    {copied ? "Copied" : "Copy Code"}
                  </Button>
                </Tooltip>
              </CopyToClipboard>
            </ButtonGroup>

            <ButtonGroup
              style={{ marginTop: 20, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}
              variant="outlined"
              aria-label="button group"
              disableElevation={true}
            >
              <Button
                style={{ fontFamily: "Lato", borderRadius: 5 }}
                color={"success"}
                startIcon={<WhatsApp />}
                variant="contained"
                onClick={shareViaWhatsApp}
              >
                Share on WhatsApp
              </Button>
              <Button
                style={{ fontFamily: "Lato", borderRadius: 5 }}
                color={"primary"}
                startIcon={<MailOutline />}
                variant="contained"
                onClick={shareViaEmail}
              >
                Share via Mail
              </Button>
            </ButtonGroup>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default ReferralPopup;

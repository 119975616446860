import { configureStore } from '@reduxjs/toolkit';
import globalStateReducer from '../GlobalState/globalState';

export const store = configureStore({
  reducer: {
    globalState: globalStateReducer,
  },
});

export default store;

import React, { Component } from 'react';
import { removeSecureItem } from '../../utilsFunctions';
class NotFound extends Component {
    componentDidMount(){
        removeSecureItem('token')
        removeSecureItem('loggedinUser')
        removeSecureItem('userid')
        removeSecureItem('loggedinUserCompany')
        this.props.history.push("/login")
    }
    render() {
        return (
            <div></div>
        )
    }
}
export default NotFound
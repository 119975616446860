import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import "date-fns";
import Select from "react-select";
import DateRangePicker from "react-daterange-picker";
import { extendMoment } from "moment-range";
import originalMoment from "moment";
import { CalendarMonth, DeleteSharp, EditSharp } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Sidebar from "../sidebar/sidebar";
import { companylogo } from "../../assets/images";
import {
  getAllAssetTypesDataAPI,
  getAllAssetsDataAPI,
  deleteAssetAPI,
  getAllBankAccountsDataAPI,
} from "../../service/service";
import "./AssetManagement.scss";
import Axios from "axios";
import { BaseUrl } from "../../Environment";
import { getSecureItem, removeSecureItem, setSecureItem } from "../../utilsFunctions";

const moment = extendMoment(originalMoment);

const Assets = () => {
  const history = useHistory();
  const loggedinUser = getSecureItem("loggedinUser");

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    time: 2000,
    variant: "success",
  });

  const [bankAccountData, setBankAccountData] = useState([]);
  const [assetId, setAssetId] = useState(null);
  const [assetData, setAssetData] = useState([]);
  const [assetTypeData, setAssetTypeData] = useState([]);
  const [deleteAssetModal, setDeleteAssetModal] = useState(false);
  const [dateRangeModal, setDateRangeModal] = useState(false);
  const [dateRange, setDateRange] = useState();
  const [dateRangeModal2, setDateRangeModal2] = useState(false);
  const [dateRange2, setDateRange2] = useState();
  const [assetTypeFilter, setAssetTypeFilter] = useState(null);
  const [accountIdFilter, setAccountIdFilter] = useState("");
  const [accountIdFilter2, setAccountIdFilter2] = useState("");

  const fetchAssetData = async () => {
    try {
      const companyId = loggedinUser?.companyId;
      const userId = loggedinUser?.userId;
      const userType = loggedinUser?.userType;

      const AssetRes = await getAllAssetsDataAPI({
        companyId,
        userId,
        userType,
        assetTypeFilter,
        accountIdFilter,
        accountIdFilter2,
        startDateFilter: dateRange?.start
          ? dateRange.start.startOf("d").format("YYYY-MM-DD HH:mm:ss")
          : null,
        endDateFilter: dateRange?.end
          ? dateRange.end.endOf("d").format("YYYY-MM-DD HH:mm:ss")
          : null,
        startDateFilter2: dateRange2?.start
          ? dateRange2.start.startOf("d").format("YYYY-MM-DD HH:mm:ss")
          : null,
        endDateFilter2: dateRange2?.end
          ? dateRange2.end.endOf("d").format("YYYY-MM-DD HH:mm:ss")
          : null,
      });

      if (AssetRes?.status == 200 && AssetRes?.data) {
        setAssetData(AssetRes?.data);
      } else {
        setToastMsg({
          msg: "No data available.",
          time: 2000,
          variant: "error",
        });

        setTimeout(() => {
          handleOpen();
        }, 500);
        setAssetData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAssetTypesData = async () => {
    try {
      const companyId = loggedinUser?.companyId;

      const AssetTypesRes = await getAllAssetTypesDataAPI(companyId);

      const valueLabelData = AssetTypesRes?.data?.map((item) => ({
        label: item.title,
        value: item.assetTypeId,
      }));

      if (AssetTypesRes?.status == 200 && AssetTypesRes?.data) {
        setAssetTypeData(valueLabelData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBAData = async () => {
    try {
      const companyId = loggedinUser?.companyId;

      const data = {
        companyId,
        searchQuery: "",
      };

      const resp = await getAllBankAccountsDataAPI(data);

      if (resp?.data?.length > 0) {
        setBankAccountData(
          resp?.data?.map((item) => ({
            label: item.bankName,
            value: item.accountId,
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBAData();
    fetchAssetTypesData();
  }, []);

  useEffect(() => {
    fetchAssetData();
  }, [
    assetTypeFilter,
    accountIdFilter,
    accountIdFilter2,
    dateRange,
    dateRange2,
  ]); // Add dependencies as necessary

  const handleDelete = async () => {
    try {
      await deleteAssetAPI(assetId);
      setDeleteAssetModal(false);
      setToastMsg({
        msg: `Asset Deleted Successfully.`,
        time: 3000,
        variant: "success",
      });

      setTimeout(() => {
        handleOpen();
      }, 500);

      setTimeout(() => {
        fetchAssetData();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    assetTypeData &&
    assetData && (
      <>
        <Sidebar />

        <Snackbar
          open={open}
          autoHideDuration={toastMsg.time}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={toastMsg.variant}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {toastMsg.msg}
          </Alert>
        </Snackbar>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"10px 100px"}
        >
          <img src={companylogo} alt="logo" />
        </Stack>

        <Stack
          className="assetmanagementpage"
          spacing={1}
          padding={"10px 20px 10px 100px"}
        >
          <Grid container>
            <Grid item md={6} sm={4}></Grid>
            <Grid item md={3} sm={4} sx={{ padding: "0 10px" }}>
              <Button
                className="muiCustomButton"
                variant="outlined"
                onClick={() => history.push("assettypes")}
              >
                Manage Asset Types
              </Button>
            </Grid>
            <Grid item md={3} sm={4} sx={{ padding: "0 10px" }}>
              <Button
                className="GreenBTN"
                variant="filled"
                onClick={() => {
                  removeSecureItem("isAssetUpdate");
                  removeSecureItem("assetId");
                  history.push("/addupdateasset");
                }}
              >
                + Add Asset
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ width: "98%", padding: "10px", backgroundColor: "#fff" }}>
            <Typography
              variant="body2"
              marginBlock={1}
              fontSize={15}
              fontWeight={700}
            >
              Filter Assets
            </Typography>
            <Grid container alignItems={"center"}>
              <Grid
                item
                md={4}
                xs={12}
                sx={{ padding: "0 10px", marginTop: "20px" }}
              >
                <Select
                  options={assetTypeData}
                  placeholder="Select Asset Types"
                  value={
                    assetTypeFilter &&
                    assetTypeData?.find((item) => item.value == assetTypeFilter)
                  }
                  onChange={(val) => setAssetTypeFilter(val?.value)}
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                sx={{ padding: "0 10px", marginTop: "20px" }}
              >
                <Select
                  options={bankAccountData}
                  placeholder="Select Accounts (Purchase)"
                  value={
                    accountIdFilter &&
                    bankAccountData?.find(
                      (item) => item.value == accountIdFilter
                    )
                  }
                  onChange={(val) => setAccountIdFilter(val?.value)}
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                sx={{ padding: "0 10px", marginTop: "20px" }}
              >
                <Select
                  options={bankAccountData}
                  placeholder="Select Accounts (Sold)"
                  value={
                    accountIdFilter2 &&
                    bankAccountData?.find(
                      (item) => item.value == accountIdFilter2
                    )
                  }
                  onChange={(val) => setAccountIdFilter2(val?.value)}
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                sx={{ padding: "0 10px", marginTop: "20px" }}
              >
                <Button
                  onClick={() => setDateRangeModal(true)}
                  variant="outlined"
                  fullWidth
                  startIcon={<CalendarMonth />}
                >
                  Select Date Range (Purchase)
                </Button>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                sx={{ padding: "0 10px", marginTop: "20px" }}
              >
                <Button
                  onClick={() => setDateRangeModal2(true)}
                  variant="outlined"
                  fullWidth
                  startIcon={<CalendarMonth />}
                >
                  Select Date Range (Sold)
                </Button>
              </Grid>
            </Grid>
            <Stack
              mt={2}
              mr={1}
              direction={"row"}
              alignSelf={"flex-end"}
              justifyContent={"flex-end"}
            >
              <Button
                className="muiCustomButton"
                onClick={() => {
                  setAccountIdFilter("");
                  setAccountIdFilter2("");
                  setAssetTypeFilter(null);
                  setDateRange();
                  setDateRange2();
                }}
                variant="outlined"
              >
                Reset
              </Button>
            </Stack>
          </Box>

          <Card style={{ marginTop: "10px" }}>
            <div className="tableLaylout">
              <Table aria-label="sticky table" className="table">
                <TableHead>
                  <TableRow className="tablHeadRowPayment">
                    <TableCell className="tablHeadCellPayment">
                      Asset Type
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Purchase/Opening
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Purchase/Opening Price
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Purchase/Opening Date
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Bank Account (Purchase)
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">Sold</TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Sold Price
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Sold Date
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Bank Account (Sold)
                    </TableCell>
                    <TableCell className="tablHeadCellPayment">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assetData.map((data) => {
                    const {
                      assetId,
                      assetTypeId,
                      isPurchase,
                      date,
                      value,
                      srcAccId,
                      isSold,
                      soldDate,
                      soldValue,
                      destAccId,
                    } = data;
                    const selectedType = assetTypeData.find(
                      (item) => item.value == assetTypeId
                    );

                    const srcAccount = bankAccountData.find(
                      (item) => item.value == srcAccId
                    );

                    const destAccount = bankAccountData.find(
                      (item) => item.value == destAccId
                    );

                    return (
                      <TableRow key={assetId} className="tableRow">
                        <TableCell>{selectedType?.label}</TableCell>
                        <TableCell>
                          {isPurchase ? "Purchase" : "Opening"}
                        </TableCell>
                        <TableCell>{value}</TableCell>
                        <TableCell>
                          {originalMoment(date).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>{srcAccount?.label}</TableCell>
                        <TableCell>{isSold ? "Yes" : "No"}</TableCell>
                        <TableCell>{soldValue}</TableCell>
                        <TableCell>
                          {originalMoment(soldDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>{destAccount?.label}</TableCell>
                        <TableCell>
                          <Stack direction={"row"} spacing={1}>
                            <IconButton
                              color="primary"
                              aria-label="update"
                              onClick={() => {
                                setSecureItem("assetId", assetId);
                                setSecureItem("isAssetUpdate", true);
                                history.push("/addupdateasset");
                              }}
                            >
                              <EditSharp />
                            </IconButton>
                            <IconButton
                              color="error"
                              aria-label="delete"
                              onClick={() => {
                                setAssetId(assetId);
                                setDeleteAssetModal(true);
                              }}
                            >
                              <DeleteSharp />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </Card>
        </Stack>

        {/* Delete Asset Modal */}
        <Dialog
          open={deleteAssetModal}
          onClose={() => setDeleteAssetModal(false)}
        >
          <DialogTitle>Delete Asset</DialogTitle>
          <DialogContent>
            <Typography fontSize={15} variant="h6">
              Are you sure, You want to delete this Asset record?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="outlined"
                onClick={() => setDeleteAssetModal(false)}
              >
                Cancel
              </Button>
              <Button color="error" variant="contained" onClick={handleDelete}>
                Delete
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>

        {/* Date Range Modal */}
        <Dialog open={dateRangeModal} onClose={() => setDateRangeModal(false)}>
          <DialogTitle>Select Date</DialogTitle>
          <DialogContent>
            <DateRangePicker
              value={dateRange}
              onSelect={setDateRange}
              singleDateRange
            />
            <Stack spacing={1} marginTop={1}>
              <Typography fontSize={15} textAlign={"center"} variant="h6">
                Start Date:{" "}
                {dateRange?.start
                  ? dateRange.start.startOf("d").format("DD/MM/YYYY")
                  : "-"}
              </Typography>
              <Typography fontSize={15} textAlign={"center"} variant="h6">
                End Date:{" "}
                {dateRange?.end
                  ? dateRange.end.startOf("d").format("DD/MM/YYYY")
                  : "-"}
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Date Range Modal 2 */}
        <Dialog
          open={dateRangeModal2}
          onClose={() => setDateRangeModal2(false)}
        >
          <DialogTitle>Select Date</DialogTitle>
          <DialogContent>
            <DateRangePicker
              value={dateRange2}
              onSelect={setDateRange2}
              singleDateRange
            />
            <Stack spacing={1} marginTop={1}>
              <Typography fontSize={15} textAlign={"center"} variant="h6">
                Start Date:{" "}
                {dateRange2?.start
                  ? dateRange2.start.startOf("d").format("DD/MM/YYYY")
                  : "-"}
              </Typography>
              <Typography fontSize={15} textAlign={"center"} variant="h6">
                End Date:{" "}
                {dateRange2?.end
                  ? dateRange2.end.startOf("d").format("DD/MM/YYYY")
                  : "-"}
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>
      </>
    )
  );
};

export default Assets;

import Axios from "axios";
import CryptoJS from "crypto-js";
import { BaseUrl } from "./Environment";
import store from "../src/components/GlobalState/store";

const SECRET_KEY = store.getState().globalState?.secretKey;

// Encrypt and store in localStorage
export const setSecureItem = (key, value) => {
  try {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
    localStorage.setItem(key, encryptedData);
  } catch (error) {
    console.error("Error encrypting data:", error);
  }
};

// Retrieve and decrypt from localStorage
export const getSecureItem = (key) => {
  try {
    const encryptedData = localStorage.getItem(key);
    if (!encryptedData) return null;

    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    console.error("Error decrypting data:", error);
    return null;
  }
};

// Remove item from localStorage
export const removeSecureItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error("Error removing data:", error);
  }
};

const loggedinUser = getSecureItem("loggedinUser");

const currencyArray = [
  { code: "AUD", symbol: "$" },
  { code: "BHD", symbol: ".د.ب‎" },
  { code: "BDT", symbol: "৳" },
  { code: "BWP", symbol: "P" },
  { code: "BRL", symbol: "R$" },
  { code: "KHR", symbol: "៛" },
  { code: "COP", symbol: "$" },
  { code: "EGP", symbol: "£" },
  { code: "GHS", symbol: "¢" },
  { code: "HKD", symbol: "$" },
  { code: "INR", symbol: "₹" },
  { code: "IDR", symbol: "Rp" },
  { code: "JMD", symbol: "J$" },
  { code: "KES", symbol: "KSh" },
  { code: "KWD", symbol: "د.ك " },
  { code: "MYR", symbol: "RM" },
  { code: "MUR", symbol: "₨" },
  { code: "MXN", symbol: "$" },
  { code: "NAD", symbol: "$" },
  { code: "NZD", symbol: "$" },
  { code: "NGN", symbol: "₦" },
  { code: "OMR", symbol: "﷼" },
  { code: "PKR", symbol: "₨" },
  { code: "PHP", symbol: "₱" },
  { code: "QAR", symbol: "﷼" },
  { code: "RWF", symbol: "R₣" },
  { code: "SAR", symbol: "﷼" },
  { code: "SGD", symbol: "$" },
  { code: "ZAR", symbol: "R" },
  { code: "LKR", symbol: "₨" },
  { code: "TZS", symbol: "TSh" },
  { code: "THB", symbol: "฿" },
  { code: "AED", symbol: " د.إ" },
  { code: "VND", symbol: "₫" },
];

export function getCurrencySymbol(code) {
  for (let i = 0; i < currencyArray.length; i++) {
    if (currencyArray[i].code == code) {
      return currencyArray[i].symbol;
    }
  }
  return "Code not found";
}

export function trialDaysRemaining(startDateStr) {
  const trialPeriodDays = 14;
  // Convert the start date string to a Date object
  const startDate = new Date(startDateStr);
  
  // Get the current date
  const currentDate = new Date();
  
  // Calculate the end date of the trial period
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + trialPeriodDays);
  
  // Calculate the remaining days
  const remainingTime = +endDate - +currentDate;
  const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
  
  // Ensure the remaining days are not negative
  return Math.max(remainingDays, 0);
}

export const getUserTypeDetails = async (userType, companyId,userId) => {
  try {
    const token = getSecureItem("token");
    const headers = {
      Authorization: "Bearer " + token,
    };

    const result = await Axios.get(
      `${BaseUrl}/userType/getUserTypeDetails?userType=${userType}&companyId=${companyId}&userId=${userId}`,
      {
        headers,
      }
    );

    setSecureItem("UsersAccess",
      Object.fromEntries(
        Object.entries(result.data).filter(
          ([key, value]) => typeof value === "boolean"
        )
      )
    );
  } catch (error) {
    console.log("error: " + JSON.stringify(error));
  }
};

export const getAllSettings = async () => {
  try {
    const token = getSecureItem("token");
    const headers = {
      Authorization: "Bearer " + token,
    };

    const result = await Axios.get(
      `${BaseUrl}/settings/getAllSettings?companyId=${loggedinUser?.companyId}&userId=${loggedinUser?.userId}`,
      {
        headers,
      }
    );

    if (result.data) {
      const loanSettings = result.data?.map((val) => ({
        autoApprove: val?.autoApprove,
        autoSms: val?.autoSms,
        settingsId: val?.settingsId
      }))[0];
      
      setSecureItem('LoanSettings', loanSettings);      
    }
  } catch (error) {
    console.log("error: " + JSON.stringify(error));
  }
};

// Function to compress image
export const compressImage = (file) => {
  
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    // Read the image as a data URL
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set canvas dimensions to match the original image
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image on the canvas
        ctx.drawImage(img, 0, 0);

        // Compress image by exporting it to a Blob
        canvas.toBlob(
          (blob) => {
            if (blob.size > 100 * 1024) {
              resolve(compressImage(file, 0.7 - 0.1)); // Recompress if size is > 100KB
            } else {
              resolve(blob); // Return compressed image as Blob
            }
          },
          "image/jpeg",
          0.7 // Image quality (0.0 - 1.0)
        );
      };

      img.onerror = (err) => {
        reject(err);
      };
    };

    reader.onerror = (err) => {
      reject(err);
    };
  });
};

export function generateRandomString(length = 10) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function canAddLoans(planId, planEndDate, maxLoansCanBeAdded, currentlyActiveLoans) {
  // console.log({planId, planEndDate, maxLoansCanBeAdded, currentlyActiveLoans});
  
  // Special case for planId 3: Unlimited loans
  if (planId == 3) {
    return true;
  }

  if (planId != 0 && (!planEndDate || new Date(planEndDate) <= new Date())) {
    return false;
  }

  if (currentlyActiveLoans >= maxLoansCanBeAdded) {
    return false;
  }

  return true; // All conditions satisfied
}

export function canAddBorrowers(planId, planEndDate, maxBorrowersCanBeAdded, currentlyActiveBorrowers) {
  // console.log({planId, planEndDate, maxLoansCanBeAdded, currentlyActiveLoans});

  // Special case for planId 3: Unlimited borrowers
  if (planId == 3) {
    return true;
  }
    
  if (planId != 0 && (!planEndDate || new Date(planEndDate) <= new Date())) {
    return false;
  }

  if (currentlyActiveBorrowers >= maxBorrowersCanBeAdded) {
    return false;
  }

  return true; // All conditions satisfied
}
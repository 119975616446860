import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import {
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Paper,
  Button,
  Stack,
  Menu,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Pagination,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import Axios from "axios";
import Sidebar from "../../sidebar/sidebar";
import { companylogo } from "../../../assets/images";
import { BaseUrl } from "../../../Environment";
import moment from "moment";
import { FilterAlt } from "@mui/icons-material";
import Customdaterange from "../../modals/customdaterange/customdaterange";
import { getSecureItem } from "../../../utilsFunctions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e1f0fa",
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    cursor: "pointer",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const loanTableHeader = {
  loanId: "Loan Id",
  firstName: "Borrower Name",
  loanAmount: "Lent Amount",
  rejectionDate: "Rejection Date",
  rejectionReason: "Rejection Reason",
  sourceName: "Source Name",
};

const collectionTableHeader = {
  SrNo: "Sr. No.",
  dueDate: "Due Date",
  openingBalance: "Opening Balance",
  Installment: "Installment Amount",
  closingBalance: "Closing Balance",
};

const RejectionReport = () => {
  let loggedinUser = getSecureItem("loggedinUser");
  let companyCountry = getSecureItem("companyCountry");

  const [reportTableData, setReportTableData] = useState([]);
  const [loanClnData, setLoanClnData] = useState([]);
  const [loanTenure, setLoanTenure] = useState("All");
  const [loanId, setLoanId] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [currency, setCurrency] = useState(companyCountry?.symbol);
  const [userType, setUserType] = useState(loggedinUser?.userType);
  const [datePickerM, setDatePickerM] = useState(false);
  const [startDateR, setStartDateR] = useState();
  const [endDateR, setEndDateR] = useState();
  const [sumLoanAmt, setSumLoanAmt] = useState(0);
  const [selectedBorrower, setSelectedBorrower] = useState("");
  const [collectionModal, setCollectionModal] = useState(false);

  // ======== Filter modal open/close ========
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const openFilter = Boolean(anchorElFilter);
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  function processLoanData(data) {
    const result = {};

    data.forEach((entry) => {
      const loanIDs = entry.loanId;
      const customer = entry.customer;
      const collectorId = entry.collectorId;
      const loanCollections = entry?.loanCollections;

      if (!result[loanIDs]) {
        result[loanIDs] = {
          loanId: loanIDs,
          customer,
          collectorId,
          loanTenureType: entry?.loanTenureType,
          loanTenureVal: entry?.loanTenure,
          loanAmount: parseFloat(entry?.totalAmountToCollect),
          loanStatus: entry?.loanStatus,
          loanPaymentStatus: entry?.loanPaymentStatus,
          collectedDate: entry?.collectedDate,
          createdAt: entry?.createdAt,
          startDate: entry?.startDate,
          approvalDate: entry?.approvalDate,
          disbursedDate: entry?.disbursedDate,
          cancellationReason: entry?.cancellationReason,
          updatedAt: entry?.updatedAt,
          totalCollectionAmount: 0,
          totalPenaltyApplied: 0,
          pendingDues: 0,
          balanceAmount: 0,
          loanCollections: loanCollections?.map((data) => ({
            dueDate: data?.dueDate,
            openingBalance: parseFloat(data?.openingBalance),
            Installment: parseFloat(data?.Installment),
            closingBalance: parseFloat(data?.closingBalance),
          })),
        };
      }

      if (loanCollections) {
        result[loanIDs].pendingDues =
          entry?.loanTenure - loanCollections.length;
        result[loanIDs].totalCollectionAmount = loanCollections.reduce(
          (total, collection) => {
            return total + parseFloat(collection.collectionAmount);
          },
          0
        );
        result[loanIDs].totalPenaltyApplied = loanCollections.reduce(
          (total, collection) => {
            return total + parseFloat(collection.PenaltyApplied ?? 0);
          },
          0
        );
        result[loanIDs].balanceAmount = parseFloat(loanCollections?.slice(-1)[0]?.closingBalance) ||
        parseFloat(entry?.totalAmountToCollect);
      }
    });

    return Object.values(result);
  }

  function processBlockData(data) {
    const result = {};

    data.forEach((entry) => {
      const loanIDs = entry.loanId;
      const loanCollections = entry?.loanCollections?.filter(
        (data) => data?.collectionStatus != null
      );

      if (!result[loanIDs]) {
        result[loanIDs] = {
          loanId: loanIDs,
          loanAmount: parseFloat(entry?.totalAmountToCollect),
          totalCollectionAmount: 0,
          totalPenaltyApplied: 0,
          balanceAmount: 0,
        };
      }

      if (loanCollections) {
        result[loanIDs].pendingDues =
          entry?.loanTenure - loanCollections.length;
        result[loanIDs].totalCollectionAmount = loanCollections.reduce(
          (total, collection) => {
            return total + parseFloat(collection.collectionAmount);
          },
          0
        );
        result[loanIDs].totalPenaltyApplied = loanCollections.reduce(
          (total, collection) => {
            return total + parseFloat(collection.PenaltyApplied ?? 0);
          },
          0
        );
        result[loanIDs].balanceAmount = parseFloat(loanCollections?.slice(-1)[0]?.closingBalance) ||
        parseFloat(entry?.totalAmountToCollect);
      }
    });

    return Object.values(result);
  }

  const getLoansData = async () => {
    try {
      setLoading(true);

      const token = getSecureItem("token");
      const companyId = getSecureItem("loggedinUserCompany");
      let collectorId = loggedinUser?.userId;

      const headers = {
        Authorization: "Bearer " + token,
      };

      const payLoad = {
        companyId,
        collectorId,
        loanStatus: "Cancelled",
        caFilter: false,
        loanTenureType: loanTenure,
        limit: 20,
        page,
        userType,
        startDateR,
        endDateR,
      };

      const res = await Axios.post(
        `${BaseUrl}/loan/getLoanwiseReport`,
        payLoad,
        {
          headers,
        }
      );

      const { data: rawData } = res;

      const loanData = processLoanData(rawData?.result);
      const blockData = processBlockData(rawData?.totalData);

      if(loanData?.length > 0){
        setReportTableData(loanData);
      } else {
        setReportTableData([]);
      }
      setSumLoanAmt(
        blockData?.reduce(
          (acc, value) => acc + parseFloat(value?.loanAmount),
          0
        )
      );
      setTotalPages(Math.ceil(parseFloat(res?.data?.totalRecords) / 20));

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getLoansData();
  }, [page, loanTenure, datePickerM, endDateR]);

  const SequenceFilter = [
    { value: "onetime", label: "Onetime" },
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "All", label: "All" },
  ];

  const handleChangeSequence = (event, val) => {
    setPage(1);
    setLoanTenure(val);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleCustomDateRange = (details) => {
    if (details) {
      const dateRange = Object.values(details);

      setStartDateR(moment(dateRange[0]).format("DD MMM YYYY HH:mm:ss"));
      setEndDateR(moment(dateRange[1]).format("DD MMM YYYY HH:mm:ss"));

      setDatePickerM(false);
    }
  };

  const handleReset = () => {
    setDatePickerM(false);
    setStartDateR();
    setEndDateR();
    setLoanTenure("All");
    getLoansData();
    handleCloseFilter();
  };

  const [sortedData, setSortedData] = useState([]);
  const [sortedDataCln, setSortedDataCln] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Handle sorting by a specific column
  const handleSortNew = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (reportTableData?.length > 0) {
      let sortedArray = [...reportTableData];

      if (sortConfig.key) {
        const aa = sortedArray?.sort((a, b) => {
          const aVal = sortConfig.key === "firstName" ? a?.customer[sortConfig.key] : sortConfig.key === "sourceName" ? a?.customer?.user?.firstName :  a[sortConfig.key];
          const bVal = sortConfig.key === "firstName" ? b?.customer[sortConfig.key] : sortConfig.key === "sourceName" ? b?.customer?.user?.firstName :  b[sortConfig.key];

          // Sorting logic for dates in 'DD/MM/YYYY' format
          if (sortConfig.key === "date") {
            const dateA = moment(aVal, "DD/MM/YYYY");
            const dateB = moment(bVal, "DD/MM/YYYY");
            return sortConfig.direction === "asc"
              ? +dateA - +dateB
              : +dateB - +dateA;
          }

          // Numeric sorting
          if (typeof aVal === "number" && typeof bVal === "number") {
            return sortConfig.direction === "asc" ? aVal - bVal : bVal - aVal;
          }

          // String sorting
          if (typeof aVal === "string" && typeof bVal === "string") {
            return sortConfig.direction === "asc"
              ? aVal?.localeCompare(bVal)
              : bVal?.localeCompare(aVal);
          }

          return 0;
        });
      }
      setSortedData(sortedArray);
    } else {
      setSortedData([]);
    }

    if (loanClnData?.length > 0) {
      let sortedArrayCln = [...loanClnData];

      if (sortConfig.key) {
        const aa = sortedArrayCln?.sort((a, b) => {
          const aVal = a[sortConfig.key];
          const bVal = b[sortConfig.key];

          // Sorting logic for dates in 'DD/MM/YYYY' format
          if (sortConfig.key === "date") {
            const dateA = moment(aVal, "DD/MM/YYYY");
            const dateB = moment(bVal, "DD/MM/YYYY");
            return sortConfig.direction === "asc"
              ? +dateA - +dateB
              : +dateB - +dateA;
          }

          // Numeric sorting
          if (typeof aVal === "number" && typeof bVal === "number") {
            return sortConfig.direction === "asc" ? aVal - bVal : bVal - aVal;
          }

          // String sorting
          if (typeof aVal === "string" && typeof bVal === "string") {
            return sortConfig.direction === "asc"
              ? aVal?.localeCompare(bVal)
              : bVal?.localeCompare(aVal);
          }

          return 0;
        });
      }
      setSortedDataCln(sortedArrayCln);
    } else {
      setSortedDataCln([]);
    }
  }, [sortConfig, reportTableData, loanClnData]);

  return (
    <>
      <Sidebar />
      <div style={{ marginLeft: "73px", height: "100vh" }}>
        {loading ? (
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid container direction={"column"}>
            <Grid item sx={{ backgroundColor: "#fff" }}>
              <div style={{ margin: 5 }}>
                <img src={companylogo} width={200} height={40} alt="logo" />
              </div>
            </Grid>
            <Grid item container paddingX={4} paddingY={2}>
              <Grid item xs={12} sm={6} md={4} lg={3} marginTop={2}>
                <Card
                  elevation={3}
                  sx={{
                    marginRight: 5,
                    borderRadius: 3,
                    backgroundColor: "#fff",
                  }}
                >
                  <CardHeader
                      title={
                        <div style={{ fontSize: 20 }}>
                          {sumLoanAmt} {currency}
                        </div>
                      }
                    />
                  <CardContent style={{padding: 5}}>
                  <CardHeader
                  style={{padding: 5}}
                    title={<div style={{ fontSize: 15 }}>Loan Amount Rejected</div>}
                  />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} marginTop={2}></Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} marginTop={2}>
                <ToggleButtonGroup
                  color="primary"
                  value={loanTenure}
                  exclusive
                  onChange={handleChangeSequence}
                  aria-label="Platform"
                  size="small"
                >
                  {SequenceFilter?.map((filter, i) => (
                    <ToggleButton key={i} value={filter?.value}>
                      {filter?.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} marginTop={2}>
                <Button
                  id="filter-button"
                  aria-controls={openFilter ? "filter-basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openFilter ? "true" : undefined}
                  onClick={handleClickFilter}
                  variant="outlined"
                  endIcon={<FilterAlt />}
                >
                  Filter
                </Button>
              </Grid>
              <Menu
                id="filter-basic-menu"
                anchorEl={anchorElFilter}
                open={openFilter}
                onClose={handleCloseFilter}
                MenuListProps={{
                  "aria-labelledby": "filter-button",
                  style: {
                    backgroundColor: "#2b3d63",
                    color: "#fff",
                    boxShadow: "#122344 1px 0px 5px 1px",
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setDatePickerM(true);
                  }}
                >
                  Custom Date Range
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Reset
                </MenuItem>
              </Menu>
            </Grid>
            {loanId ? null : (
              <Box paddingX={4} paddingY={2}>
                <p className="Fonts">NOTE: Click on record to see details</p>
              </Box>
            )}

            <Grid item container paddingX={4} paddingY={2}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          {Object.keys(loanTableHeader).map((property) => (
                            <StyledTableCell key={property}>
                              <TableSortLabel
                                active={sortConfig.key === property}
                                direction={
                                  sortConfig.key === property ? sortConfig.direction : "asc"
                                }
                                onClick={() => handleSortNew(property)}
                              >
                                {loanTableHeader[property]}
                              </TableSortLabel>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedData?.map((data, index) => {
                          return (
                            <StyledTableRow
                              key={index}
                              onClick={() => {
                                setLoanId(data?.loanId);
                                setSelectedBorrower(
                                  data?.customer?.firstName +
                                    " " +
                                    data?.customer?.lastName
                                );
                                setLoanClnData(data?.loanCollections);
                                setCollectionModal(true);
                              }}
                            >
                              <StyledTableCell>{data?.loanId}</StyledTableCell>
                              <StyledTableCell>
                                {
                                  <Stack>
                                    <div>
                                      {data?.customer?.firstName +
                                        " " +
                                        data?.customer?.lastName}
                                    </div>
                                    {data?.customer?.cust_id ? (
                                      <div>({data?.customer?.cust_id})</div>
                                    ) : null}
                                  </Stack>
                                }
                              </StyledTableCell>
                              <StyledTableCell>
                                {data?.loanAmount}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data?.updatedAt
                                  ? moment(data?.updatedAt).format("DD/MM/YYYY")
                                  : ""}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data?.cancellationReason
                                  ? data?.cancellationReason
                                  : ""}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data?.customer?.user
                                  ? data?.customer?.user?.firstName +
                                    " " +
                                    data?.customer?.user?.lastName
                                  : ""}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    size="large"
                    style={{ marginTop: 10 }}
                  />
            </Grid>
          </Grid>
        )}
      </div>
      <Dialog
        open={collectionModal}
        fullWidth={true}
        maxWidth="lg"
        onClose={() => setCollectionModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {`Loan Collection Table | Borrower's Name: ${selectedBorrower}, Loan ID: ${loanId}`}
        </DialogTitle>
        <DialogContent>
        {loanId ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        {Object.keys(collectionTableHeader).map((property) => (
                          <StyledTableCell key={property}>
                            <TableSortLabel
                              active={sortConfig.key === property}
                              direction={
                                sortConfig.key === property ? sortConfig.direction : "asc"
                              }
                              onClick={() => handleSortNew(property)}
                            >
                              {collectionTableHeader[property]}
                            </TableSortLabel>
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedDataCln?.map((data, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>
                            {moment(data?.dueDate).format("DD/MM/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell>
                            {data?.openingBalance}
                          </StyledTableCell>
                          <StyledTableCell>{data?.Installment}</StyledTableCell>
                          <StyledTableCell>
                            {data?.closingBalance}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <></>
                )
          }
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={() => setCollectionModal(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Customdaterange
        open={datePickerM}
        close={() => setDatePickerM(false)}
        isOnlyPast={true}
        save={handleCustomDateRange}
      />
    </>
  );
};

export default withNamespaces()(RejectionReport);

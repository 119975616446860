import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx-js-style";
import {
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogContentText,
  Stack,
} from "@mui/material";
import { DateRangeOutlined } from "@mui/icons-material";
import Axios from "axios";
import moment from "moment";
import { BaseUrl } from "../../../Environment";
import Sidebar from "../../sidebar/sidebar";
import Customdaterange from "../../modals/customdaterange/customdaterange";
import { companylogo } from "../../../assets/images";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSecureItem } from "../../../utilsFunctions";

const DetailedAnalysisReport = () => {
  toast.configure({});

  const [dateModal, setDateModal] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("year").startOf("day").format("DD MMM YYYY HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("year").endOf("day").format("DD MMM YYYY HH:mm:ss")
  );
  const [loanStatus, setLoanStatus] = useState("Open");
  const [paymentStatus, setPaymentStatus] = useState("All");
  const [loanSequence, setLoanSequence] = useState("All");
  const [data, setData] = useState([]);

  const getDetailedAnalysisData = async () => {
    try {
      const token = getSecureItem("token");
      const companyId = getSecureItem("loggedinUserCompany");

      const headers = {
        Authorization: "Bearer " + token,
      };

      const payLoad = {
        companyId: companyId,
        loanPaymentStatus: paymentStatus,
        loanStatus: loanStatus,
        loanTenureType: loanSequence,
        startDate,
        endDate,
      };

      const resp = await Axios.post(
        `${BaseUrl}/loan/getDetailedAnalysis`,
        payLoad,
        {
          headers,
        }
      );

      if (resp.data?.result) {
        setData(resp.data?.result);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getDetailedAnalysisData();
  }, [loanStatus, paymentStatus, loanSequence, dateModal, endDate]);

  const handleCustomDateRange = (details) => {
    if (details) {
      const dateRange = Object.values(details);

      setStartDate(moment(dateRange[0]).format("DD MMM YYYY HH:mm:ss"));
      setEndDate(moment(dateRange[1]).format("DD MMM YYYY HH:mm:ss"));

      setDateModal(false);
    }
  };

  const handleReset = () => {
    setDateModal(false);
    setStartDate(
      moment().startOf("year").startOf("day").format("DD MMM YYYY HH:mm:ss")
    );
    setEndDate(
      moment().endOf("year").endOf("day").format("DD MMM YYYY HH:mm:ss")
    );
    setLoanStatus("Open");
    setPaymentStatus("All");
    setLoanSequence("All");
    setData([]);
  };

  function calculateLoanCollections(
    loanCollections,
    loanTenureType,
    loanTenure
  ) {
    loanCollections.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
    const collections = {};
    let periodKeyPrefix = "";

    switch (loanTenureType) {
      case "daily":
        periodKeyPrefix = "day";
        break;
      case "weekly":
        periodKeyPrefix = "week";
        break;
      case "monthly":
        periodKeyPrefix = "month";
        break;
      case "onetime":
        periodKeyPrefix = "onetime";
        break;
      default:
        throw new Error("Invalid loanTenureType");
    }

    for (let i = 1; i <= loanTenure; i++) {
      if (i >= loanCollections?.length) {
        collections[`${periodKeyPrefix}${i}`] = "X";
      } else {
        collections[`${periodKeyPrefix}${i}`] = 0;
      }
    }

    loanCollections.forEach((item, index) => {
      if (index < loanTenure) {
        let periodKey = `${periodKeyPrefix}${index + 1}`;
        collections[periodKey] =
          item.collectionStatus === "Paid"
            ? parseFloat(item.collectionAmount)
            : item.collectionStatus === "Not Paid"
            ? 0
            : "";
      }
    });

    return collections;
  }

  function calculateAmounts(totalAmountToCollect, loanCollections) {
    const loanCollectionsAlt = loanCollections
      ?.filter((data) => data?.collectionStatus != null);

    loanCollections.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));

    // Initialize variables
    let lastNonNullClosingBalance = 0;
    let totalCollectedAmount = 0;
    let totalPenaltyAmount = 0;

    // Iterate through the loanCollections array
    const calc = loanCollections?.forEach((collection) => {
      // Check if collectionStatus is not null
      if (collection.collectionStatus !== null) {
        // Update the last non-null closing balance
        lastNonNullClosingBalance = parseFloat(collection.closingBalance);
      }

      if (collection.PenaltyApplied !== null) {
        totalPenaltyAmount += parseFloat(collection.PenaltyApplied);
      }
    });

    // Calculate the balance amount based on the last non-null closing balance
    const balanceAmount = lastNonNullClosingBalance;

    // Calculate the total collected amount by subtracting the last closing balance from the total amount
    totalCollectedAmount = parseFloat(totalAmountToCollect) - balanceAmount;

    // Return the results
    return {
      totalCollectedAmount:
        balanceAmount == 0 &&
        parseFloat(totalCollectedAmount) == parseFloat(totalAmountToCollect)
          ? 0
          : totalCollectedAmount,
      balanceAmount: loanCollectionsAlt?.length === 0 ? parseFloat(totalAmountToCollect) : balanceAmount,
      totalPenaltyAmount: totalPenaltyAmount,
    };
  }

  function convertApiDataToDesiredFormat(loanData) {
    const formattedData = [];
    let currentDate = null;

    const aaa = loanData?.forEach((item) => {
      const balances = calculateAmounts(
        item?.totalAmountToCollect,
        item?.loanCollections
      );

      const loanDate = moment(item?.startDate).format("DD/MM/YYYY");

      if (currentDate !== loanDate) {
        currentDate = loanDate;
        formattedData.push({ date: `Loan Start Date: ${currentDate}` });
      }

      const areaLine = item?.customer?.areaLine;
      formattedData.push({
        startDate: item?.startDate,
        loanNo: item?.loanId,
        name: `${item?.customer?.firstName || ""} ${
          item?.customer?.lastName || ""
        } ${item?.customerId ? `(${item?.customerId})` : ""}`.trim(),
        place:
          areaLine == ""
            ? ""
            : areaLine == null
            ? ""
            : areaLine == "null"
            ? ""
            : areaLine,
        loanTenureType: item?.loanTenureType,
        loanTenure: item?.loanTenure,
        loanAmount: item?.totalAmountToCollect,
        loanStatus: item?.loanStatus,
        collections: calculateLoanCollections(
          item?.loanCollections,
          item?.loanTenureType,
          item?.loanTenure
        ),
        nip: balances?.balanceAmount,
        penalty: balances?.totalPenaltyAmount,
        total: balances?.totalCollectedAmount,
      });
    });

    return formattedData;
  }

  // Define your function
  const generateExcelWithXLSX = (loanData) => {
    const wb = XLSX.utils.book_new(); // Create a new workbook
    const wsData = []; // Your worksheet data
    let maxColumns = 0; // To store the maximum number of columns
    const merges = []; // Array to keep track of merges

    // Convert the raw loan data into the desired format
    let desiredData = convertApiDataToDesiredFormat(loanData);

    // Group loans by date, then by loanTenure and loanTenureType, in ascending order of tenure.
    const startDateGroups = {};
    const abc = desiredData?.forEach((row) => {
      if (row.startDate) {
        const startDateKey = moment(row.startDate).format("DD/MM/YYYY");
        if (!startDateGroups[startDateKey]) {
          startDateGroups[startDateKey] = [];
        }
        startDateGroups[startDateKey].push(row);
      }
    });

    // Initialize a variable to track the last processed start date
    let lastProcessedDate = null;

    // Iterate over each start date group
    Object.keys(startDateGroups).forEach((startDateKey) => {
      const loans = startDateGroups[startDateKey];

      // Sort loans by `loanTenure` in ascending order
      loans.sort((a, b) => a.loanTenure - b.loanTenure);

      let lastLoanDuration = null;

      loans.forEach((row) => {
        const tenure = row?.loanTenure;
        const loanTT = row?.loanTenureType;
        const loanDurationKey = `${tenure}-${loanTT}`;
        const currentLoanDateKey = moment(row?.startDate).format("DD/MM/YYYY");

        // Add start date header only if the date is different from the last processed date
        if (currentLoanDateKey !== lastProcessedDate) {
          wsData.push([
            {
              v: `Loan Start Date: ${currentLoanDateKey}`,
              s: {
                alignment: { horizontal: "center" },
                font: { sz: 15, color: { rgb: "0000FF" } },
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
          ]);
          merges.push({
            s: { r: wsData.length - 1, c: 0 },
            e: { r: wsData.length - 1, c: 1 },
          });
          
          // Update lastProcessedDate to the current date
          lastProcessedDate = currentLoanDateKey;
        }

        // Add Loan Duration header only if it hasn't been added for this group
        if (lastLoanDuration !== loanDurationKey) {
          const dueDatesRow = [];
          for (let i = 1; i <= tenure; i++) {
            const dueDate = moment(row?.startDate)
              .add(
                i - 1,
                loanTT === "daily"
                  ? "days"
                  : loanTT === "weekly"
                  ? "weeks"
                  : "months"
              )
              .format("DD/MM/YYYY");
            dueDatesRow.push({
              v: dueDate,
              s: { alignment: { horizontal: "center" }, font: { bold: true } },
            }); // Center-align due dates
          }

          // Add Loan Duration row
          wsData.push([
            {
              v: `Loan Duration: ${tenure === 1 ? "" : tenure} ${
                loanTT === "daily"
                  ? "days"
                  : loanTT === "weekly"
                  ? "weeks"
                  : loanTT === "monthly"
                  ? "months"
                  : "One Time"
              }`,
              s: { font: { bold: true }, alignment: { horizontal: "center" } },
            },
            "",
            "",
            "",
            "",
            ...dueDatesRow,
          ]);
          merges.push({
            s: { r: wsData.length - 1, c: 0 },
            e: { r: wsData.length - 1, c: 1 },
          }); // Merge first 2 cells

          // Dynamic headers
          const dynamicHeader = [];
          for (let i = 1; i <= tenure; i++) {
            dynamicHeader.push({
              v:
                loanTT === "daily"
                  ? `Day ${i}`
                  : loanTT === "weekly"
                  ? `Week ${i}`
                  : loanTT === "monthly"
                  ? `Month ${i}`
                  : "Installment",
              s: { alignment: { horizontal: "center" }, font: { bold: true } },
            });
          }

          // Final header row
          const finalHeader = [
            {
              v: "Loan ID",
              s: { alignment: { horizontal: "center" }, font: { bold: true } },
            },
            {
              v: "Name",
              s: { alignment: { horizontal: "center" }, font: { bold: true } },
            },
            {
              v: "Area/Line",
              s: { alignment: { horizontal: "center" }, font: { bold: true } },
            },
            {
              v: "Loan Amount",
              s: { alignment: { horizontal: "center" }, font: { bold: true } },
            },
            {
              v: "Loan Status",
              s: { alignment: { horizontal: "center" }, font: { bold: true } },
            },
            ...dynamicHeader,
            {
              v: "Collected Amt",
              s: { alignment: { horizontal: "center" }, font: { bold: true } },
            },
            {
              v: "Penalty Amt",
              s: { alignment: { horizontal: "center" }, font: { bold: true } },
            },
            {
              v: "Balance Amt",
              s: { alignment: { horizontal: "center" }, font: { bold: true } },
            },
          ];
          wsData.push(finalHeader);
          maxColumns = finalHeader.length + 5;
        }

        // Add loan data row
        const loanRow = [
          { v: row.loanNo, s: { alignment: { horizontal: "center" } } },
          { v: row.name, s: { alignment: { horizontal: "center" } } },
          { v: row.place, s: { alignment: { horizontal: "center" } } },
          { v: row.loanAmount, s: { alignment: { horizontal: "center" } } },
          { v: row.loanStatus, s: { alignment: { horizontal: "center" } } },
          ...Object.values(row.collections).map((value) => ({
            v: value,
            s: { alignment: { horizontal: "center" } },
          })),
          { v: row.total, s: { alignment: { horizontal: "center" } } },
          { v: row.penalty, s: { alignment: { horizontal: "center" } } },
          { v: row.nip, s: { alignment: { horizontal: "center" } } },
        ];
        wsData.push(loanRow);

        // Update the last loan duration
        lastLoanDuration = loanDurationKey;
      });
    });

    // Create a new worksheet from the wsData array
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Adjust the column width for better visibility
    const columnWidths = [
      { wch: 12 },
      { wch: 20 },
      { wch: 12 },
      { wch: 13 },
      { wch: 12 },
      ...Array(maxColumns).fill({ wch: 10 }),
    ];
    ws["!cols"] = columnWidths;

    // Set merged cells
    ws["!merges"] = merges;

    // Append the worksheet to the workbook and write the file
    XLSX.utils.book_append_sheet(wb, ws, "Loan Report");
    XLSX.writeFile(
      wb,
      `${loanSequence}_LoanSequenceReport_${moment().format(
        "DDMMYYHHmmss"
      )}.xlsx`
    );
  };

  const allGenerateExcelWithXLSX = (loanData) => {
    const wb = XLSX.utils.book_new(); // Create a new workbook

    // Filter the loan data into different categories
    const dailyData = loanData.filter(
      (item) => item.loanTenureType === "daily"
    );
    const weeklyData = loanData.filter(
      (item) => item.loanTenureType === "weekly"
    );
    const monthlyData = loanData.filter(
      (item) => item.loanTenureType === "monthly"
    );
    const onetimeData = loanData.filter(
      (item) => item.loanTenureType === "onetime"
    );

    // Helper function to create a sheet with loan data
    const createSheetWithLoanData = (sheetName, loanData) => {
      if (!loanData.length) return null; // Return null if no data

      const wsData = []; // Your worksheet data
      let maxColumns = 0; // To store the maximum number of columns
      const merges = []; // Array to keep track of merges

      let desiredData = convertApiDataToDesiredFormat(loanData);

      // Group loans by date, then by loanTenure and loanTenureType, in ascending order of tenure.
      const startDateGroups = {};
      const abc = desiredData?.forEach((row) => {
        if (row.startDate) {
          const startDateKey = moment(row.startDate).format("DD/MM/YYYY");
          if (!startDateGroups[startDateKey]) {
            startDateGroups[startDateKey] = [];
          }
          startDateGroups[startDateKey].push(row);
        }
      });

      // Initialize a variable to track the last processed start date
      let lastProcessedDate = null;

      // Iterate over each start date group
      Object.keys(startDateGroups).forEach((startDateKey) => {
        const loans = startDateGroups[startDateKey];

        // Sort loans by `loanTenure` in ascending order
        loans.sort((a, b) => a.loanTenure - b.loanTenure);

        let lastLoanDuration = null;

        loans.forEach((row) => {
          const tenure = row?.loanTenure;
          const loanTT = row?.loanTenureType;
          const loanDurationKey = `${tenure}-${loanTT}`;
          const currentLoanDateKey = moment(row?.startDate).format(
            "DD/MM/YYYY"
          );

          // Add start date header only if the date is different from the last processed date
          if (currentLoanDateKey !== lastProcessedDate) {
            wsData.push([
              {
                v: `Loan Start Date: ${currentLoanDateKey}`,
                s: {
                  alignment: { horizontal: "center" },
                  font: { sz: 15, color: { rgb: "0000FF" } },
                  alignment: { horizontal: "center", vertical: "center" },
                },
              },
            ]);
            merges.push({
              s: { r: wsData.length - 1, c: 0 },
              e: { r: wsData.length - 1, c: 1 },
            });
            
            // Update lastProcessedDate to the current date
            lastProcessedDate = currentLoanDateKey;
          }

          // Add Loan Duration header only if it hasn't been added for this group
          if (lastLoanDuration !== loanDurationKey) {
            const dueDatesRow = [];
            for (let i = 1; i <= tenure; i++) {
              const dueDate = moment(row?.startDate)
                .add(
                  i - 1,
                  loanTT === "daily"
                    ? "days"
                    : loanTT === "weekly"
                    ? "weeks"
                    : "months"
                )
                .format("DD/MM/YYYY");
              dueDatesRow.push({
                v: dueDate,
                s: {
                  alignment: { horizontal: "center" },
                  font: { bold: true },
                },
              }); // Center-align due dates
            }

            // Add Loan Duration row
            wsData.push([
              {
                v: `Loan Duration: ${tenure === 1 ? "" : tenure} ${
                  loanTT === "daily"
                    ? "days"
                    : loanTT === "weekly"
                    ? "weeks"
                    : loanTT === "monthly"
                    ? "months"
                    : "One Time"
                }`,
                s: {
                  font: { bold: true },
                  alignment: { horizontal: "center" },
                },
              },
              "",
              "",
              "",
              "",
              ...dueDatesRow,
            ]);
            merges.push({
              s: { r: wsData.length - 1, c: 0 },
              e: { r: wsData.length - 1, c: 1 },
            }); // Merge first 2 cells

            // Dynamic headers
            const dynamicHeader = [];
            for (let i = 1; i <= tenure; i++) {
              dynamicHeader.push({
                v:
                  loanTT === "daily"
                    ? `Day ${i}`
                    : loanTT === "weekly"
                    ? `Week ${i}`
                    : loanTT === "monthly"
                    ? `Month ${i}`
                    : "Installment",
                s: {
                  alignment: { horizontal: "center" },
                  font: { bold: true },
                },
              });
            }

            // Final header row
            const finalHeader = [
              {
                v: "Loan ID",
                s: {
                  alignment: { horizontal: "center" },
                  font: { bold: true },
                },
              },
              {
                v: "Name",
                s: {
                  alignment: { horizontal: "center" },
                  font: { bold: true },
                },
              },
              {
                v: "Area/Line",
                s: {
                  alignment: { horizontal: "center" },
                  font: { bold: true },
                },
              },
              {
                v: "Loan Amount",
                s: {
                  alignment: { horizontal: "center" },
                  font: { bold: true },
                },
              },
              {
                v: "Loan Status",
                s: {
                  alignment: { horizontal: "center" },
                  font: { bold: true },
                },
              },
              ...dynamicHeader,
              {
                v: "Collected Amt",
                s: {
                  alignment: { horizontal: "center" },
                  font: { bold: true },
                },
              },
              {
                v: "Penalty Amt",
                s: {
                  alignment: { horizontal: "center" },
                  font: { bold: true },
                },
              },
              {
                v: "Balance Amt",
                s: {
                  alignment: { horizontal: "center" },
                  font: { bold: true },
                },
              },
            ];
            wsData.push(finalHeader);
            maxColumns = finalHeader.length + 5;
          }

          // Add loan data row
          const loanRow = [
            { v: row.loanNo, s: { alignment: { horizontal: "center" } } },
            { v: row.name, s: { alignment: { horizontal: "center" } } },
            { v: row.place, s: { alignment: { horizontal: "center" } } },
            { v: row.loanAmount, s: { alignment: { horizontal: "center" } } },
            { v: row.loanStatus, s: { alignment: { horizontal: "center" } } },
            ...Object.values(row.collections).map((value) => ({
              v: value,
              s: { alignment: { horizontal: "center" } },
            })),
            { v: row.total, s: { alignment: { horizontal: "center" } } },
            { v: row.penalty, s: { alignment: { horizontal: "center" } } },
            { v: row.nip, s: { alignment: { horizontal: "center" } } },
          ];
          wsData.push(loanRow);

          // Update the last loan duration
          lastLoanDuration = loanDurationKey;
        });
      });

      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // Adjust the column width for better visibility
      const columnWidths = [
        { wch: 12 },
        { wch: 20 },
        { wch: 12 },
        { wch: 13 },
        { wch: 12 },
        ...Array(maxColumns).fill({ wch: 10 }),
      ];
      ws["!cols"] = columnWidths;

      ws["!merges"] = merges;
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    };

    // Create sheets for each tenure type if data is available
    createSheetWithLoanData("Daily Loans", dailyData);
    createSheetWithLoanData("Weekly Loans", weeklyData);
    createSheetWithLoanData("Monthly Loans", monthlyData);
    createSheetWithLoanData("One Time Loans", onetimeData);

    // Write the workbook only if it has at least one sheet
    if (wb.SheetNames.length > 0) {
      XLSX.writeFile(
        wb,
        `Multi_LoanSequenceReport_${moment().format("DDMMYYHHmmss")}.xlsx`
      );
    } else {
      console.log("No data available to generate Excel report.");
    }
  };

  return (
    <>
      <Sidebar />

      <div style={{ marginLeft: "73px", height: "100vh" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ backgroundColor: "#fff", paddingInline: 2, height: "70px" }}
        >
          <div>
            <img src={companylogo} width={200} height={40} alt="logo" />
          </div>
          <strong
            style={{
              fontWeight: "bold",
              fontFamily: "Lato",
              color: "#000",
              fontSize: "20px",
            }}
          >
            Detailed Analysis Report
          </strong>
        </Stack>

        <Stack spacing={2} sx={{ paddingInline: 3 }}>
          <div>
            <DialogContentText
              style={{
                fontWeight: "bold",
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Date Range
            </DialogContentText>
            <FormControl
              className="assetmanagementpage"
              component="fieldset"
              style={{ border: "none" }}
            >
              <Button
                variant="outlined"
                className="muiCustomButton"
                style={{ width: endDate ? 300 : 100 }}
                onClick={() => setDateModal(true)}
                startIcon={<DateRangeOutlined />}
              >
                {startDate ? moment(startDate).format("DD/MM/YYYY") : null}{" "}
                {endDate ? "-" : null}{" "}
                {endDate ? moment(endDate).format("DD/MM/YYYY") : null}
              </Button>
            </FormControl>
          </div>
          <div>
            <DialogContentText
              style={{
                fontWeight: "bold",
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Loan Status
            </DialogContentText>
            <FormControl component="fieldset" style={{ border: "none" }}>
              <RadioGroup
                aria-label="position"
                name="position"
                value={loanStatus}
                onChange={(e) => setLoanStatus(e.target.value)}
                row
              >
                <FormControlLabel
                  value="Open"
                  control={<Radio style={{ color: "#4d66f0" }} />}
                  label="Open"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Closed"
                  control={<Radio style={{ color: "#4d66f0" }} />}
                  label="Closed"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Cancelled"
                  control={<Radio style={{ color: "#4d66f0" }} />}
                  label="Cancelled"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="All"
                  control={<Radio style={{ color: "#4d66f0" }} />}
                  label="All"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <DialogContentText
              style={{
                fontWeight: "bold",
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Payment Status
            </DialogContentText>
            <FormControl component="fieldset" style={{ border: "none" }}>
              <RadioGroup
                aria-label="position"
                name="position"
                value={paymentStatus}
                onChange={(e) => setPaymentStatus(e.target.value)}
                row
              >
                <FormControlLabel
                  value="Due"
                  control={<Radio style={{ color: "#4d66f0" }} />}
                  label="Due"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="OverDue"
                  control={<Radio style={{ color: "#4d66f0" }} />}
                  label="OverDue"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="All"
                  control={<Radio style={{ color: "#4d66f0" }} />}
                  label="All"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <DialogContentText
              style={{
                fontWeight: "bold",
                fontFamily: "Lato",
                color: "#000",
              }}
            >
              Loan Sequence
            </DialogContentText>
            <FormControl component="fieldset" style={{ border: "none" }}>
              <RadioGroup
                aria-label="position"
                name="position"
                value={loanSequence}
                onChange={(e) => setLoanSequence(e.target.value)}
                row
              >
                <FormControlLabel
                  value="onetime"
                  control={<Radio style={{ color: "#4d66f0" }} />}
                  label="Onetime"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="daily"
                  control={<Radio style={{ color: "#4d66f0" }} />}
                  label="Daily"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="weekly"
                  control={<Radio style={{ color: "#4d66f0" }} />}
                  label="Weekly"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="monthly"
                  control={<Radio style={{ color: "#4d66f0" }} />}
                  label="Monthly"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="All"
                  control={<Radio style={{ color: "#4d66f0" }} />}
                  label="All"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </Stack>

        <Stack
          className="assetmanagementpage"
          direction={"row"}
          spacing={3}
          sx={{ paddingInline: 3, marginTop: 5 }}
        >
          <Button
            className="muiCustomButton"
            variant="outlined"
            style={{ width: 200 }}
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            className="GreenBTN"
            style={{ width: 300 }}
            onClick={() => {
              if (data?.length > 0) {
                if (loanSequence === "All") {
                  allGenerateExcelWithXLSX(data);
                } else {
                  generateExcelWithXLSX(data);
                }
              } else {
                toast.error("No records available to download.", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                });
              }
            }}
          >
            Export/Download
          </Button>
        </Stack>

        <Customdaterange
          open={dateModal}
          close={() => setDateModal(false)}
          isOnlyPast={true}
          save={handleCustomDateRange}
        />
      </div>
    </>
  );
};

export default DetailedAnalysisReport;

import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import "../MessageForCustomer/MessageForCustomer.scss";
import { withRouter } from "react-router-dom";
import { BaseUrl, headers } from "../../../Environment";
import { withNamespaces } from "react-i18next";
import { Autocomplete, Box, TextField } from "@mui/material";
import { getSecureItem } from "../../../utilsFunctions";

const axios = require("axios");
class MessageForCustomer extends Component {
  constructor() {
    super();
    this.state = {
      cAgentId: "",
    };
  }

  savechanges() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getSecureItem("token"),
    };
    const payload = {
      loanId: this.props?.rowData?.loanId,
      collectorId: this.state?.cAgentId
    }

    axios.put(`${BaseUrl}/loan/updateCollectionAgent`, payload , {
      headers
    })
      .then((resp) => {
        console.log("message", resp);
        if (resp.status == 200) {
          toast.success(resp.data?.msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((err) => {
        if (err.request.status !== 200) {
          toast.error("Please try again!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
      });
    this.props.close();
  }
  cancelchanges() {
    this.props.close();
  }
  render() {
    toast.configure({});
    const { t, caData } = this.props;

    return (
      <div>
        <div className="messagePopup">
          <Dialog
            open={this.props.open}
            className="messagedialogbox"
            style={{ borderRadius: "5px" }}
          >
            <div className="messagedialogboxContainer">
              <DialogTitle id="customized-dialog-title" className="TitleDiv">
                <div className="deleteconfirmation">
                  <p className="Fonts btnSizeFont confrmmessage">
                    Update Collection Agent
                  </p>
                  <Autocomplete
                    id=""
                    fullWidth
                    options={caData}
                    autoHighlight
                    getOptionLabel={(option) => option.firstName+" "+option.lastName}
                    onChange={(event, newValue) => {
                      this.setState({ cAgentId: newValue?.userId });
                    }}
                    renderOption={(props, option) => {
                      return (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.firstName+" "+option.lastName}
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a collection agent"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "",
                        }}
                      />
                    )}
                  />
                </div>
              </DialogTitle>
              <DialogContent className="bottomPart">
                <div className="bottomPartOne">
                  <Button
                    className="cancelbutton Fonts"
                    onClick={this.cancelchanges.bind(this)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="savebutton Fonts"
                    onClick={this.savechanges.bind(this)}
                  >
                    Update
                  </Button>
                </div>
              </DialogContent>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
export default withNamespaces()(withRouter(MessageForCustomer));

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';
import { createHashHistory } from "history";
import { PrimeReactProvider } from "primereact/api";
// import {createBrowserHistory} from "history";
// import {BrowserRouter as Router} from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './components/GlobalState/store';

import { Router } from "react-router-dom";
const history = createHashHistory();
// const history = createBrowserHistory();


ReactDOM.render(
    <Router history={history}>
        <PrimeReactProvider>
        <Provider store={store}>
        <App />
        </Provider>
        </PrimeReactProvider>
    </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

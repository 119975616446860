import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  secretKey: "",
  loggedinUser: {},
};

const globalStateSlice = createSlice({
  name: 'globalState',
  initialState,
  reducers: {
    setSecretKey: (state, action) => {
      state.secretKey = action.payload; // Update the secret key
    },
    setLoggedinUser: (state, action) => {
      state.loggedinUser = action.payload; // Update the logged in user object
    },
  },
});

export const { setSecretKey, setLoggedinUser } = globalStateSlice.actions;
export default globalStateSlice.reducer;
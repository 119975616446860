import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import "./DeleteLoanAlt.scss";
import { withRouter } from "react-router-dom";
import { BaseUrl, headers } from "../../../Environment";
import { withNamespaces } from "react-i18next";
import { Autocomplete, Box, TextField } from "@mui/material";
import { getSecureItem } from "../../../utilsFunctions";

const axios = require("axios");
class DeleteLoanAlt extends Component {
  constructor() {
    super();
    this.state = {
      loanId: "",
    };
  }

  savechanges() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getSecureItem("token"),
    };
    const payload = {
      id: this.props?.rowData?.loanId,
    }

    axios.post(`${BaseUrl}/loan/deleteLoan`, payload , {
      headers
    })
      .then((resp) => {
        console.log("message", resp);
        if (resp.status == 200) {
          toast.success("Loan deleted successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((err) => {
        if (err.request.status !== 200) {
          toast.error("Please try again!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
      });
    
    this.props.close();
  }
  cancelchanges() {
    this.props.close();
  }
  render() {
    toast.configure({});
    const { t, rowData } = this.props;

    return (
      <div>
        <div className="messagePopup">
          <Dialog
            open={this.props.open}
            className="messagedialogbox"
            style={{ borderRadius: "5px" }}
          >
            <div className="messagedialogboxContainer">
              <DialogTitle id="customized-dialog-title" className="TitleDiv">
                <div className="deleteconfirmation">
                  <p className="Fonts btnSizeFont confrmmessage">
                    Are you sure you want to delete this loan {`(${rowData?.loanId})`}?
                  </p>
                </div>
              </DialogTitle>
              <DialogContent className="bottomPart">
                <div className="bottomPartOne">
                  <Button
                    className="cancelbutton Fonts"
                    onClick={this.cancelchanges.bind(this)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="savebutton Fonts"
                    onClick={this.savechanges.bind(this)}
                  >
                    Delete
                  </Button>
                </div>
              </DialogContent>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
export default withNamespaces()(withRouter(DeleteLoanAlt));
